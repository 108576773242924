import { Col, Row, Typography } from 'antd';
import React from 'react';

const ChangeQuoteComponent = ({ quotesData = null }) => {
  if (quotesData?.oldPackage && quotesData?.newPackage) {
    return (
      <Row justify="center">
        <Col span={24}>
          <Typography.Title className="text-center font-bold" level={3}>
            Quote Upgraded Successfully
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Paragraph className="font-size-18">
            The quote upgrade request has been processed successfully. You have
            upgraded from {quotesData?.oldPackage} to {quotesData?.newPackage}
          </Typography.Paragraph>
        </Col>
      </Row>
    );
  }
  return (
    <Row className="font-bold" justify="center">
      <Col span={24}>
        <Typography.Paragraph className="font-size-18">
          As a part of our security measures, quote links are valid for a
          limited time to protect your sensitive information. Unfortunately, the
          link you had received to view the quote has now expired.
        </Typography.Paragraph>
      </Col>
      <Col span={24}>
        <Typography.Paragraph className="font-size-18">
          If you still require the quote or wish to proceed with the process,
          kindly reach out to our team, and we will be more than happy to assist
          you promptly.
        </Typography.Paragraph>
      </Col>
    </Row>
  );
};

export default ChangeQuoteComponent;
