import { Form } from 'antd';
import { filter, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { DYNAMIC_FIELDS_TYPE, PRIMARY_COLOR } from '../../common/constants';
import CommonPickListWidget from '../CommonPickListWidget';
import SelectComponent from '../SelectComponent';

const { Option } = SelectComponent;

const RenderSystemPickList = ({
  widgetConfig = null,
  editData = null,
  savedData,
  setSavedData,
  defaultPickListOption = null,
  form = null,
  pageId = null,
  primaryColor = PRIMARY_COLOR
}) => {
  const { dispatch } = useContext(AppContext);
  const displayStyle = widgetConfig?.displayStyle;
  const images = widgetConfig?.images;
  const imagePreview = widgetConfig?.imagePreview;
  const multiSelection = widgetConfig?.multiSelection;
  const renderDescriptions = widgetConfig?.optionDescriptions;
  const otherConfig = widgetConfig?.otherConfig;
  const description = widgetConfig?.description;
  const descriptionText = widgetConfig?.descriptionText;
  const hideContinueButton = widgetConfig?.hideContinueButton;
  const required = widgetConfig?.required;
  const fieldId = widgetConfig?.selectedSystemFieldBlock?.selectedSystemFieldId;
  const [multipleKeys, setMultipleKeys] = useState([]);
  const [selectedKey, setSelectedKey] = useState(null);

  useEffect(() => {
    if (editData && !isEmpty(editData?.[fieldId])) {
      if (!isEmpty(editData?.[fieldId]?.value)) {
        setSavedData({
          systemFields: {
            ...savedData?.systemFields,
            [fieldId]: {
              type: DYNAMIC_FIELDS_TYPE?.PICK_LIST,
              value: [...editData?.[fieldId]?.value],
              pageId
            }
          }
        });

        form?.setFieldsValue({
          systemFields: {
            [fieldId]: {
              [DYNAMIC_FIELDS_TYPE?.PICK_LIST]: {
                value: [...editData?.[fieldId]?.value]
              }
            }
          }
        });
        if (!multiSelection) {
          setSelectedKey(editData?.[fieldId]?.value?.[0]);
        } else {
          setMultipleKeys([...multipleKeys, ...editData?.[fieldId]?.value]);
        }
      } else {
        form?.setFieldsValue({
          systemFields: {
            [fieldId]: {
              [DYNAMIC_FIELDS_TYPE?.PICK_LIST]: {
                value: []
              }
            }
          }
        });
        if (!multiSelection) {
          setSelectedKey(null);
        } else {
          setMultipleKeys([]);
        }
      }
    } else if (defaultPickListOption) {
      setSavedData({
        systemFields: {
          ...savedData?.systemFields,
          [fieldId]: {
            type: DYNAMIC_FIELDS_TYPE?.PICK_LIST,
            value: [defaultPickListOption],
            pageId
          }
        }
      });
      form?.setFieldsValue({
        systemFields: {
          [fieldId]: {
            [DYNAMIC_FIELDS_TYPE?.PICK_LIST]: {
              value: defaultPickListOption
            }
          }
        }
      });
      if (!multiSelection) {
        setSelectedKey(defaultPickListOption);
      } else {
        setMultipleKeys([...multipleKeys, defaultPickListOption]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, defaultPickListOption]);

  // picklist show/hide button logic
  useEffect(() => {
    if (hideContinueButton) {
      // if user want to hide cta button
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false
      });
    } else if (required && multiSelection) {
      // check if picklist is required and if picklist is multiselect and user doesn't select any option from picklist then hide cta button
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: multipleKeys?.length !== 0
      });
    } else if (required && !multiSelection) {
      // check if picklist is required and if picklist is not multiselect and user doesn't select any option from picklist then hide cta button
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: selectedKey
      });
    } else {
      // by default button will be shown to user
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: true
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleKeys, selectedKey]);

  const handleRedirect = (item) => {
    if (!multiSelection) {
      if (selectedKey !== item?.blockId) {
        setSelectedKey(item?.blockId);
        setSavedData({
          systemFields: {
            ...savedData?.systemFields,
            [fieldId]: {
              type: DYNAMIC_FIELDS_TYPE?.PICK_LIST,
              value: [item?.blockId],
              pageId
            }
          }
        });
        if (hideContinueButton) {
          form?.submit();
        }
      } else {
        setSelectedKey();
        setSavedData({
          systemFields: {
            ...savedData?.systemFields,
            [fieldId]: {
              type: DYNAMIC_FIELDS_TYPE?.PICK_LIST,
              value: [],
              pageId
            }
          }
        });
      }
    }
    if (!multipleKeys?.includes(item?.blockId)) {
      setMultipleKeys([...multipleKeys, item?.blockId]);
      setSavedData({
        systemFields: {
          ...savedData?.systemFields,
          [fieldId]: {
            type: DYNAMIC_FIELDS_TYPE?.PICK_LIST,
            value: [...multipleKeys, item?.blockId],
            pageId
          }
        }
      });
    } else {
      const newSelectedOptions = multipleKeys?.filter((selectedItem) => {
        return selectedItem !== item?.blockId;
      });
      setMultipleKeys([...newSelectedOptions]);
      setSavedData({
        systemFields: {
          ...savedData?.systemFields,
          [fieldId]: {
            type: DYNAMIC_FIELDS_TYPE?.PICK_LIST,
            value: [...newSelectedOptions],
            pageId
          }
        }
      });
    }
  };

  const handlePickListChange = (keys) => {
    if (keys?.length > 0) {
      if (!multiSelection) {
        setSelectedKey(keys);
      } else {
        setMultipleKeys([...multipleKeys, keys]);
      }
    } else if (!multiSelection) {
      setSelectedKey(null);
      form?.setFieldsValue({
        systemFields: {
          [fieldId]: {
            [DYNAMIC_FIELDS_TYPE?.PICK_LIST]: {
              value: null
            }
          }
        }
      });
    } else {
      setMultipleKeys([]);
      form?.setFieldsValue({
        systemFields: {
          [fieldId]: {
            [DYNAMIC_FIELDS_TYPE?.PICK_LIST]: {
              value: []
            }
          }
        }
      });
    }
  };
  return (
    <div>
      {displayStyle === 'DROPDOWN' ? (
        <Form.Item
          name={['systemFields', fieldId, 'PICK_LIST', 'value']}
          rules={[
            { required, message: 'This field is required. Please fill it in.' }
          ]}
        >
          <SelectComponent
            className="fill-width pick-list-drop-down"
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
            }
            optionLabelProp="label"
            mode={multiSelection ? 'multiple' : ''}
            onChange={handlePickListChange}
          >
            {map(
              filter(otherConfig, (item) => item?.blockCheck),
              (item) => {
                return (
                  <Option
                    key={item?.blockId}
                    value={item?.blockId}
                    label={item?.label}
                  >
                    <div key={item?.blockId} className="dropdown-option">
                      <span className="option-title">{item?.label}</span>
                      {renderDescriptions && (
                        <span className="option-description">
                          {item?.blockDescription || item?.description}
                        </span>
                      )}
                    </div>
                  </Option>
                );
              }
            )}
          </SelectComponent>
        </Form.Item>
      ) : (
        <CommonPickListWidget
          displayStyle={displayStyle}
          renderImages={images}
          imagePreview={imagePreview}
          renderDescriptions={renderDescriptions}
          haveLabel
          renderItems={map(otherConfig, (item) => {
            return {
              key: item?.blockId,
              ...item
            };
          })?.filter((item) => item?.blockCheck)}
          selectedKey={selectedKey}
          multipleKeys={multipleKeys}
          form={form}
          handleRedirect={handleRedirect}
          isMultiple={multiSelection}
          keyName="blockId"
          primaryColor={primaryColor}
        />
      )}
      {description && <div className="mt-37">{descriptionText}</div>}
    </div>
  );
};

export default RenderSystemPickList;
