import { DeleteOutlined, LeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Divider, Empty, Popconfirm } from 'antd';
import { kebabCase, map } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../AppContext';
import { SUB_DOMAIN } from '../common/constants';
import {
  createLeadTracking,
  formatPrice,
  updateDataMutation
} from '../common/utils';
import history from '../historyData';
import LoaderComponent from './LoaderComponent';
import { DELETE_PROJECT } from './graphql/Mutation';
import './styles/MyCartWidgetComponent.less';

const MyCartWidgetComponent = ({
  widgetConfig = null,
  pageSequencesData = [],
  currentPageSequence = null,
  finalProjectData = [],
  refetchLeadStoreData
}) => {
  const {
    dispatch,
    state: { storedData, projectLoading }
  } = useContext(AppContext);
  const currentProject = { ...storedData };

  const [deleteProject] = useMutation(DELETE_PROJECT, {
    onError() {
      dispatch({
        type: 'SET_PROJECT_LOADING',
        data: false
      });
    }
  });

  useEffect(() => {
    // We have entry of products that doesn't have quote info, so need to replace currentQuote with the quote detail object for preventing error on back.
    refetchLeadStoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddNewProject = async () => {
    dispatch({
      type: 'SET_PROJECT_LOADING',
      data: true
    });
    const projectPage = pageSequencesData?.find(
      (item) => item?.pageConfiguration?.widgetType === 'PROJECT'
    );

    const oldProjectObj = {
      ...storedData,
      currentQuote: false
    };

    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();
    await updateDataMutation(oldProjectObj, dispatch, visitorId);

    const newProjectObj = {
      industryId: currentProject?.industryId,
      city: currentProject?.city,
      currentQuote: true,
      lat: currentProject?.lat,
      lng: currentProject?.lng,
      zipCode: currentProject?.zipCode,
      previousPageKey: currentPageSequence?.pageKey,
      shortAddress: currentProject?.shortAddress,
      slug: currentProject?.slug,
      subDomain:
        process?.env?.REACT_APP_ENV === 'localhost'
          ? SUB_DOMAIN
          : // eslint-disable-next-line no-undef
            window?.location?.hostname,
      state: currentProject?.state
    };

    if (storedData?.leadId) {
      const { slug = null } = storedData;
      const pageKey = currentPageSequence?.pageKey;
      const action = 'user clicked on add new project button';

      createLeadTracking(slug, pageKey, visitorId, storedData?.leadId, action);
    }
    await updateDataMutation(newProjectObj, dispatch, visitorId);
    dispatch({
      type: 'SET_PROJECT_LOADING',
      data: false
    });
    history?.replace(
      `/${currentProject?.slug}/${kebabCase(projectPage?.pageKey)}`
    );
  };

  const handleDeleteProject = async (id) => {
    dispatch({
      type: 'SET_PROJECT_LOADING',
      data: true
    });
    const storeDataClone = { ...storedData };
    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();

    // if user removing current project then we are storing a last created project in our storeData
    if (storeDataClone?.id === id) {
      const lastCreatedProject = finalProjectData?.findLast(
        (project) => project?.id !== id
      )?.data;
      await updateDataMutation(lastCreatedProject, dispatch, visitorId);
    }

    deleteProject({
      variables: {
        where: {
          id
        }
      }
    }).then(() => {
      refetchLeadStoreData();
    });
  };

  const handleChangeQuote = async (projectId) => {
    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();

    const quotePage = pageSequencesData?.find(
      (item) => item?.pageConfiguration?.widgetType === 'QUOTES'
    );

    if (storedData?.leadId) {
      const { slug = null } = storedData;
      const pageKey = currentPageSequence?.pageKey;
      const action = 'user clicked on change quote button';

      createLeadTracking(slug, pageKey, visitorId, storedData?.leadId, action);
    }

    // finding a page key of previous page of quote page
    const indexOfPageBeforeQuotePage = pageSequencesData?.findIndex(
      (item) => item?.pageConfiguration?.widgetType === 'QUOTES'
    );
    const pageBeforeQuotePage =
      pageSequencesData?.[indexOfPageBeforeQuotePage - 1];

    const selectedProject = finalProjectData?.find(
      (project) => project?.id === projectId
    )?.data;

    const newProject = {
      ...selectedProject,
      currentQuote: true,
      previousPageKey: pageBeforeQuotePage?.pageKey,
      previousPageTitle: pageBeforeQuotePage?.title
    };

    if (projectId !== storedData?.id) {
      const oldProjectObj = {
        ...storedData,
        currentQuote: false,
        previousPageKey: pageBeforeQuotePage?.pageKey,
        previousPageTitle: pageBeforeQuotePage?.title
      };
      await updateDataMutation(oldProjectObj, dispatch, visitorId);
    }
    await updateDataMutation(newProject, dispatch, visitorId);
    history?.replace(
      `/${currentProject?.slug}/${kebabCase(quotePage?.pageKey)}`
    );
  };

  let total = 0;

  return (
    <div className="my-cart-wrapper">
      <LoaderComponent spinning={projectLoading} setHeight={10}>
        {map(finalProjectData, (project) => {
          total += parseFloat(project?.data?.productPrice);
          return (
            <div key={project?.id}>
              <div className="fill-width d-flex justify-between align-center">
                <span className="bold-label package-title-quote-variant">
                  {project?.data?.productTitle}: {project?.data?.quoteVariant}
                </span>
                <Popconfirm
                  title="Are you sure you want to remove this project"
                  onConfirm={() => handleDeleteProject(project?.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} danger />
                </Popconfirm>
              </div>

              {/* collapse with description code goes here */}
              <div className="fill-width d-flex justify-between align-center change-quote-section">
                <div
                  onClick={() => handleChangeQuote(project?.id)}
                  className="back-link"
                >
                  <LeftOutlined className="arrow" />
                  <span>{widgetConfig?.changeQuoteButtonLabel}</span>
                </div>
                <div className="amount-section">
                  {formatPrice(project?.data?.productPrice)}
                </div>
              </div>
            </div>
          );
        })}
        {finalProjectData?.length > 0 && (
          <>
            <Divider className="cart-divider" />
            <div className="d-flex flex-vertical align-end total-amount-section">
              <span>Total</span>
              <span className="amount">{formatPrice(total)}</span>
            </div>
          </>
        )}
        {finalProjectData?.length <= 0 && !projectLoading && (
          <Empty className="empty-project" description="No Project Found!" />
        )}
        <div>
          <Button
            onClick={handleAddNewProject}
            className="fill-width mt-15 add-new-project"
          >
            {widgetConfig?.addProjectButtonLabel}
          </Button>
        </div>

        <Divider className="cart-divider" />
      </LoaderComponent>
    </div>
  );
};

export default MyCartWidgetComponent;
