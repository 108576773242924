import { Checkbox, Col, Form, Row, Tabs } from 'antd';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import defaultStairImage from '../../assets/images/stairs-default-image.png';
import { calculateNoOfSteps, calculateTotalVolume } from '../../common/utils';
import DecimalNumberComponent from '../DecimalNumberComponent';
import NumberComponent from '../NumberComponent';

const Steps = ({ stepsConfig = null }) => {
  let imageUrl;
  if (stepsConfig?.picture?.length > 0) {
    if (stepsConfig?.picture?.[0]?.url) {
      imageUrl = stepsConfig?.picture?.[0]?.url;
    } else {
      imageUrl = URL?.createObjectURL(stepsConfig?.picture?.[0]?.originFileObj);
    }
  } else {
    imageUrl = stepsConfig?.picture?.url;
  }

  return (
    <>
      {stepsConfig?.pictureCheck && (
        <div className="stairs-image-section">
          <img src={imageUrl || defaultStairImage} alt="stairs-preview" />
        </div>
      )}

      <Row>
        <Col span={22}>
          <Form.Item
            name={['dimension', 'stairs', 'steps', 'stepsWidth']}
            rules={[
              {
                required: true,
                message: 'This field is required. Please fill it in.'
              }
            ]}
          >
            <DecimalNumberComponent
              className="dimension-decimal-input"
              placeholder={stepsConfig?.width}
              decimalValue={2}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <div className="measurement-span">ft</div>
        </Col>
      </Row>
      <Row>
        <Col span={22}>
          <Form.Item
            name={['dimension', 'stairs', 'steps', 'stepsDepth']}
            rules={[
              {
                required: true,
                message: 'This field is required. Please fill it in.'
              }
            ]}
          >
            <DecimalNumberComponent
              className="dimension-decimal-input"
              placeholder={stepsConfig?.depth}
              decimalValue={2}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <div className="measurement-span">ft</div>
        </Col>
      </Row>
      <Row>
        <Col span={22}>
          <div className="custom-dimension-suffix">
            <Form.Item
              name={['dimension', 'stairs', 'steps', 'stepsHeight']}
              rules={[
                {
                  required: true,
                  message: 'This field is required. Please fill it in.'
                }
              ]}
            >
              <DecimalNumberComponent
                className="dimension-decimal-input"
                placeholder={stepsConfig?.height}
                decimalValue={2}
              />
            </Form.Item>
          </div>
        </Col>
        <Col span={2}>
          <div className="measurement-span">ft</div>
        </Col>
      </Row>
    </>
  );
};

const TotalRise = ({ totalRiseConfig = null }) => {
  let imageUrl;
  if (totalRiseConfig?.image?.length > 0) {
    if (totalRiseConfig?.image?.[0]?.url) {
      imageUrl = totalRiseConfig?.image?.[0]?.url;
    } else {
      imageUrl = URL?.createObjectURL(
        totalRiseConfig?.image?.[0]?.originFileObj
      );
    }
  } else {
    imageUrl = totalRiseConfig?.image?.url;
  }

  return (
    <>
      {totalRiseConfig?.imageCheck && (
        <div className="stairs-image-section">
          <img src={imageUrl || defaultStairImage} alt="stairs-preview" />
        </div>
      )}
      {totalRiseConfig?.additionalOptionCheck && (
        <div className="fill-width d-flex justify-center">
          <Form.Item
            name={['dimension', 'stairs', 'totalRise', 'totalRiseFlushMount']}
            valuePropName="checked"
          >
            <Checkbox readOnly className="common-checkbox">
              {totalRiseConfig?.additionalOptionText}
            </Checkbox>
          </Form.Item>
        </div>
      )}
      <Row>
        <Col span={22}>
          <Form.Item
            name={['dimension', 'stairs', 'totalRise', 'stairsTotalHeight']}
            rules={[
              {
                required: true,
                message: 'This field is required. Please fill it in.'
              }
            ]}
          >
            <DecimalNumberComponent
              className="dimension-decimal-input"
              placeholder={totalRiseConfig?.totalHeight}
              decimalValue={2}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <div className="measurement-span">ft</div>
        </Col>
      </Row>
      <Row>
        <Col span={22}>
          <Form.Item
            name={['dimension', 'stairs', 'totalRise', 'stairsTotalSteps']}
          >
            <NumberComponent
              readOnly
              placeholder={totalRiseConfig?.totalSteps}
              className="dimension-read-only"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={22}>
          <Form.Item
            name={['dimension', 'stairs', 'totalRise', 'stairsVolume']}
          >
            <DecimalNumberComponent
              className="readonly-dimension-decimal-input"
              placeholder={totalRiseConfig?.volume}
              readOnly
              decimalValue={2}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <div className="measurement-span">cu/ft</div>
        </Col>
      </Row>
    </>
  );
};

const StairsForm = ({ widgetConfig = null, form = null }) => {
  useEffect(() => {
    form?.setFieldsValue({
      additional: widgetConfig?.totalRise?.selectedCheck
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetConfig]);
  const {
    state: { storedData },
    dispatch
  } = useContext(AppContext);

  const data = { ...storedData };
  const [selectedTab, setSelectedTab] = useState();
  const widthValue = Form?.useWatch(
    ['dimension', 'stairs', 'steps', 'stepsWidth'],
    form
  );
  const depthValue = Form?.useWatch(
    ['dimension', 'stairs', 'steps', 'stepsDepth'],
    form
  );
  const heightValue = Form?.useWatch(
    ['dimension', 'stairs', 'steps', 'stepsHeight'],
    form
  );
  const totalHeightValue = Form?.useWatch(
    ['dimension', 'stairs', 'totalRise', 'stairsTotalHeight'],
    form
  );

  const [totalSteps, setTotalSteps] = useState(0);

  useEffect(() => {
    if (widgetConfig?.steps?.defaultCheck) {
      setSelectedTab('STEPS');
    }
    if (widgetConfig?.totalRise?.defaultCheck) {
      setSelectedTab('TOTAL_RISE');
    }
  }, [widgetConfig]);

  useEffect(() => {
    if (heightValue && totalHeightValue) {
      const noOfSteps = calculateNoOfSteps(totalHeightValue, heightValue);
      setTotalSteps(noOfSteps);
      form?.setFieldValue(
        ['dimension', 'stairs', 'totalRise', 'stairsTotalSteps'],
        noOfSteps
      );
    }
    if (!isEmpty(data?.dimension)) {
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: true
      });
      return;
    }

    if (widthValue && depthValue && heightValue && totalHeightValue) {
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: true
      });
    } else {
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widthValue, depthValue, heightValue, totalHeightValue]);

  useEffect(() => {
    if (widthValue && depthValue && totalSteps) {
      const volume = calculateTotalVolume(
        widthValue,
        heightValue,
        depthValue,
        totalSteps
      );
      form?.setFieldValue(
        ['dimension', 'stairs', 'totalRise', 'stairsVolume'],
        volume
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widthValue, depthValue, totalSteps]);

  const items = [
    {
      key: 'STEPS',
      label: widgetConfig?.steps?.title,
      children: <Steps stepsConfig={widgetConfig?.steps} />
    },
    {
      key: 'TOTAL_RISE',
      label: widgetConfig?.totalRise?.title,
      children: <TotalRise totalRiseConfig={widgetConfig?.totalRise} />
    }
  ];

  const handleSelectTab = (key) => {
    setSelectedTab(key);
  };
  return (
    <Tabs
      activeKey={selectedTab}
      onChange={handleSelectTab}
      items={items}
      centered
    />
  );
};

export default StairsForm;
