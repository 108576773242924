import { LeftOutlined } from '@ant-design/icons';
import { Checkbox, Divider, Form } from 'antd';
import { kebabCase, map } from 'lodash';
import React, { useContext, useEffect } from 'react';
import ReactInputMask from 'react-input-mask';
import { AppContext } from '../AppContext';
import { REGEX } from '../common/constants';
import {
  formValidatorRules,
  formatPrice,
  updateDataMutation
} from '../common/utils';
import history from '../historyData';
import InputComponent from './InputComponent';
import LoaderComponent from './LoaderComponent';
import './styles/ContactWidgetComponent.less';

const { required } = formValidatorRules;

const ContactMeWidgetComponent = ({
  widgetConfig = null,
  pageSequencesData = [],
  finalProjectData = [],
  refetchLeadStoreData,
  form = null
}) => {
  const {
    dispatch,
    state: { storedData, projectLoading, totalProject }
  } = useContext(AppContext);
  const currentProject = { ...storedData };

  useEffect(() => {
    refetchLeadStoreData();
    const currentQuoteObj = { ...storedData };
    // check whether we have leadId or not
    if (currentQuoteObj?.leadId) {
      form?.setFieldsValue({
        comments: currentQuoteObj?.comments || null,
        name: currentQuoteObj?.name || null,
        email: currentQuoteObj?.email || null,
        mobileNumber: currentQuoteObj?.mobileNumber || null
      });
    }
    dispatch({
      type: 'SET_SHOW_SUBMIT_BTN',
      data: true
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeQuote = async (projectId) => {
    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();

    const quotePage = pageSequencesData?.find(
      (item) => item?.pageConfiguration?.widgetType === 'QUOTES'
    );

    // finding a page key of previous page of quote page
    const indexOfPageBeforeQuotePage = pageSequencesData?.findIndex(
      (item) => item?.pageConfiguration?.widgetType === 'QUOTES'
    );
    const pageBeforeQuotePage =
      pageSequencesData?.[indexOfPageBeforeQuotePage - 1];

    const selectedProject = finalProjectData?.find(
      (project) => project?.id === projectId
    )?.data;

    const newProject = {
      ...selectedProject,
      currentQuote: true,
      previousPageKey: pageBeforeQuotePage?.pageKey,
      previousPageTitle: pageBeforeQuotePage?.title
    };

    if (projectId !== storedData?.id) {
      const oldProjectObj = {
        ...storedData,
        currentQuote: false,
        previousPageKey: pageBeforeQuotePage?.pageKey,
        previousPageTitle: pageBeforeQuotePage?.title
      };
      await updateDataMutation(oldProjectObj, dispatch, visitorId);
    }
    await updateDataMutation(newProject, dispatch, visitorId);
    history?.replace(
      `/${currentProject?.slug}/${kebabCase(quotePage?.pageKey)}`
    );
  };

  const PackageDisplay = () => {
    let total = 0;
    return (
      <div>
        {map(finalProjectData, (project, index) => {
          total += parseFloat(project?.data?.productPrice);
          return (
            <div key={`${project?.data?.id}-${index}`}>
              <div className="fill-width d-flex justify-between align-center">
                <span className="bold-label package-title-quote-variant">
                  {project?.data?.productTitle}: {project?.data?.quoteVariant}
                </span>
              </div>
              {/* collapse with description code goes here */}
              <div className="fill-width d-flex justify-between align-center change-quote-section">
                <div
                  onClick={() => handleChangeQuote(project?.id)}
                  className="back-link"
                >
                  <LeftOutlined className="arrow" />
                  <span>Change Quote</span>
                </div>
                <div className="amount-section">
                  {formatPrice(project?.data?.productPrice)}
                </div>
              </div>
            </div>
          );
        })}
        <Divider className="contact-divider" />
        <div className="fill-width d-flex justify-end align-end change-quote-section">
          <div className="amount-section">{formatPrice(total)}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="contact-page-wrapper">
      {widgetConfig?.orderSettings?.cartSummary && totalProject > 0 && (
        <LoaderComponent spinning={projectLoading} setHeight={10}>
          <PackageDisplay />
        </LoaderComponent>
      )}
      <div className="form-section fill-width">
        <span className="optional-comment">Optional comments</span>
        <div>
          <Form.Item name="comments">
            <InputComponent
              placeholder="Notes: ex. I have two water heaters..."
              className="mt-30"
            />
          </Form.Item>
          <div className="fill-width d-flex align-start flex-vertical">
            {widgetConfig?.contactFromSettings?.urgencyCheckboxLabelCheck && (
              <Form.Item
                name="urgent"
                initialValue={
                  widgetConfig?.contactFromSettings
                    ?.urgencyCheckboxLabelSelected
                }
                valuePropName="checked"
              >
                <Checkbox className="mt-20 common-checkbox">
                  {widgetConfig?.contactFromSettings?.urgencyCheckboxLabelText}
                </Checkbox>
              </Form.Item>
            )}

            {widgetConfig?.contactFromSettings
              ?.sendConfirmationEmailCheckboxCheck && (
              <Form.Item
                name="emailConfirmation"
                valuePropName="checked"
                initialValue={
                  widgetConfig?.contactFromSettings
                    ?.sendConfirmationEmailCheckboxSelected
                }
              >
                <Checkbox className="common-checkbox">
                  {
                    widgetConfig?.contactFromSettings
                      ?.sendConfirmationEmailCheckboxText
                  }
                </Checkbox>
              </Form.Item>
            )}
          </div>
          <Form.Item
            rules={[
              {
                required: true,
                message: `Please Enter ${widgetConfig?.contactFromSettings?.name?.label}`
              }
            ]}
            name="name"
          >
            <InputComponent
              placeholder={`${widgetConfig?.contactFromSettings?.name?.label} *`}
              className="mt-30"
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: `Please Enter ${widgetConfig?.contactFromSettings?.email?.labelText}`
              }
            ]}
          >
            <InputComponent
              placeholder={`${widgetConfig?.contactFromSettings?.email?.labelText} *`}
            />
          </Form.Item>
          <Form.Item
            name="mobileNumber"
            rules={[
              {
                ...required,
                message: `Please Enter ${widgetConfig?.contactFromSettings?.phone?.labelText}`
              },
              () => ({
                validator(rule, value) {
                  if (value) {
                    // eslint-disable-next-line no-param-reassign
                    value = value?.split(' ')?.join('');
                    const numberPattern = REGEX?.PHONE;
                    if (!numberPattern?.test(value)) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject(
                        `should be a valid ${widgetConfig?.contactFromSettings?.phone?.labelText}`
                      );
                    }
                  }
                  return Promise?.resolve();
                }
              })
            ]}
          >
            <ReactInputMask
              mask="(999) 999-9999"
              placeholder={`${widgetConfig?.contactFromSettings?.phone?.labelText} *`}
              allowClear
            >
              {(inputProps) => <InputComponent {...inputProps} />}
            </ReactInputMask>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default ContactMeWidgetComponent;
