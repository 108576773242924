import { Card } from 'antd';
import React from 'react';
import ChangeQuoteComponent from '../components/ChangeQuoteComponent';

const ChangeQuotePage = ({ quotesData = null }) => {
  return (
    <Card className="full-height-card card-body-p-32">
      <ChangeQuoteComponent quotesData={quotesData} />
    </Card>
  );
};

export default ChangeQuotePage;
