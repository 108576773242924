import { filter, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import TileGridComponent from './TileGridComponent';

const MyProjectWidgetComponent = ({
  currentPageSequence = null,
  widgetConfig = null,
  setSavedData
}) => {
  const {
    state: { storedData },
    dispatch
  } = useContext(AppContext);

  const [selectedLob, setSelectedLob] = useState(null);
  const [selectedServiceType, setSelectedServiceType] = useState(null);

  const setSubAreaId = async () => {
    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const projectWidgetObject = {
      subAreaId: selectedServiceType?.blockId,
      lineOfBusinessKey: selectedLob?.blockKey
    };
    wsmTracker?.setProjectData(projectWidgetObject);
  };

  useEffect(() => {
    const data = { ...storedData };
    if (data?.leadId) {
      const leadLobData = map(
        filter(
          widgetConfig?.otherConfig,
          (item) => item?.blockKey === data?.lobObject?.key
        ),
        (item) => {
          return {
            key: item?.blockKey,
            ...item
          };
        }
      );
      if (leadLobData?.length > 0) {
        const leadServiceTypeData = map(
          filter(
            leadLobData?.[0]?.subAreas,
            (item) => item?.blockId === data?.serviceTypeObject?.blockId
          ),
          (item) => {
            return {
              key: item?.blockKey,
              ...item
            };
          }
        );

        setSelectedServiceType({ ...leadServiceTypeData?.[0] });
      }

      setSelectedLob({ ...leadLobData?.[0] });
    } else if (data?.lobObject && data?.serviceTypeObject) {
      setSelectedLob(data?.lobObject);
      setSelectedServiceType(data?.serviceTypeObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedLob && selectedServiceType) {
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: true
      });
      const data = {
        lobObject: selectedLob,
        serviceTypeObject: selectedServiceType,
        navigateTo: selectedServiceType?.navigateTo
      };
      setSubAreaId();
      setSavedData(data);
    } else {
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLob, selectedServiceType]);

  const handleLobChange = (item) => {
    if (selectedLob?.key !== item?.key) {
      setSelectedServiceType(null);
    }
    setSelectedLob(item);
  };

  return (
    <div className="my-project-component">
      <TileGridComponent
        displayStyle={widgetConfig?.displayStyle}
        renderImages={widgetConfig?.images}
        imagePreview={widgetConfig?.imagePreview}
        renderDescriptions={widgetConfig?.optionDescriptions}
        selectedKey={selectedLob?.key}
        primaryColor={currentPageSequence?.primaryColor}
        renderItems={map(
          filter(widgetConfig?.otherConfig, (item) => item?.blockCheck),
          (item) => {
            return {
              key: item?.blockKey,
              ...item
            };
          }
        )}
        handleClick={handleLobChange}
      />
      {selectedLob && (
        <>
          <div className="service-type-text">Select service type</div>
          <TileGridComponent
            primaryColor={currentPageSequence?.primaryColor}
            displayStyle="LIST"
            renderItems={map(
              filter(selectedLob?.subAreas, (item) => item?.blockCheck),
              (item) => {
                return {
                  key: item?.blockKey,
                  ...item
                };
              }
            )}
            selectedKey={selectedServiceType?.key}
            handleClick={setSelectedServiceType}
            showCheckedIconUi={false}
            haveLabel
          />
        </>
      )}
      {widgetConfig?.description &&
        widgetConfig?.descriptionText?.length > 0 && (
          <div className="mt-37">{widgetConfig?.descriptionText}</div>
        )}
    </div>
  );
};

export default MyProjectWidgetComponent;
