import { useLazyQuery, useMutation } from '@apollo/client';
import { cloneDeep, filter, findIndex, isEmpty, kebabCase, map } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useLocation, withRouter } from 'react-router-dom';
import { AppContext } from '../AppContext';
import Error404 from '../Error404';
import client from '../apollo';
import analytics from '../common/analytics';
import { DYNAMIC_FIELDS_TYPE, ROUTES, SUB_DOMAIN } from '../common/constants';
import {
  fetchStep,
  getCurrentQuoteObject,
  updateDataMutation
} from '../common/utils';
import CommonConfigComponent from '../components/CommonConfigComponent';
import LoaderComponent from '../components/LoaderComponent';
import ChangeQuoteWrapper from '../components/changeQuote';
import { CHANGE_QUOTE } from '../components/graphql/Mutation';
import {
  GET_LEAD_STORE_DATA,
  GET_PAGE_SEQUENCES,
  VALIDATE_LEAD_ID
} from '../components/graphql/Query';
import history from '../historyData';

function getQuery() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { search = '' } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => new URLSearchParams(search), [search]);
}

const ContentRoutes = ({ match: { params: { slug = '' } = {} } = {} }) => {
  const {
    dispatch,
    state: { loading, storedData }
  } = useContext(AppContext);

  const [pageSequencesData, setPageSequencesData] = useState([]);
  const [pageSequencesSet, setPageSequencesSet] = useState(false);

  const [quotesData, setQuotesData] = useState(null);
  const [leadIdData, setLeadIdData] = useState(null);
  const [leadId, setLeadId] = useState(null);
  const query = getQuery();

  const setCurrentPage = async (leadData = null) => {
    // eslint-disable-next-line no-undef
    const wsmTracker = window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();

    try {
      const data = await client?.query({
        query: GET_LEAD_STORE_DATA,
        variables: {
          where: {
            visitorId
          }
        }
      });

      const currentQuoteObj = getCurrentQuoteObject(
        data?.data?.getLeadStoreData?.data
      );
      dispatch({
        type: 'SET_TOTAL_PROJECT_COUNT',
        data: filter(
          data?.data?.getLeadStoreData?.data,
          (project) => project?.data?.productTitle
        )?.length
      });

      const secondaryId = currentQuoteObj?.data?.navigateTo;
      if (secondaryId) {
        const { data: res } = await client?.query({
          query: GET_PAGE_SEQUENCES,
          variables: {
            where: {
              id: secondaryId
            }
          },
          fetchPolicy: 'network-only'
        });
        const secondaryData = map([...res?.pageSequences?.data], (item) => {
          return {
            ...item,
            isSecondaryPage: true
          };
        });
        if (secondaryData?.length > 0) {
          const pageSequenceDataCopy = cloneDeep(pageSequencesData);
          const projectWidgetIndex = findIndex(
            pageSequenceDataCopy,
            (item) => item?.pageConfiguration?.widgetType === 'PROJECT'
          );
          // adding secondary data after project widget
          pageSequenceDataCopy?.splice(
            projectWidgetIndex + 1,
            0,
            ...secondaryData
          );
          setPageSequencesSet(false);
          setPageSequencesData(
            map(pageSequenceDataCopy, (item, index) => {
              return {
                ...item,
                order: index + 1
              };
            })
          );
          const updatedData = {
            ...storedData,
            ...(currentQuoteObj?.id && { id: currentQuoteObj?.id }),
            isSecondaryAdded: true
          };
          await updateDataMutation(updatedData, dispatch, visitorId);
        }
      }

      setPageSequencesSet(false);
      if (currentQuoteObj?.data?.currentPage) {
        dispatch({
          type: 'SET_STORED_DATA',
          data: {
            ...currentQuoteObj?.data,
            ...(currentQuoteObj?.id && { id: currentQuoteObj?.id })
          }
        });
        fetchStep(slug, currentQuoteObj?.data?.currentPage, dispatch);
      } else {
        const firstOrderObj = pageSequencesData?.[0];
        dispatch({
          type: 'SET_LOADING',
          data: false
        });
        history?.replace(`/${slug}/${kebabCase(firstOrderObj?.pageKey)}`, {
          leadData
        });
      }
    } catch (error) {
      dispatch({
        type: 'SET_LOADING',
        data: false
      });
      setPageSequencesSet(false);
      return error;
    }
  };

  const [pageSequences] = useLazyQuery(GET_PAGE_SEQUENCES, {
    fetchPolicy: 'network-only',
    onCompleted: async (res) => {
      setPageSequencesData(
        map(res?.pageSequences?.data, (item, index) => {
          return {
            ...item,
            order: index + 1
          };
        })
      );
      // eslint-disable-next-line no-undef
      const wsmTracker = window?.Wsm?.getAsyncTracker();
      // initialize tracker
      wsmTracker?.setTenantId(res?.pageSequences?.data?.[0]?.tenantId);
      wsmTracker?.setTrackerUrl(process?.env?.REACT_APP_ANALYTICS_URL);
      wsmTracker?.setCookiePath(slug);
      wsmTracker?.setSubDomain(
        process?.env?.REACT_APP_ENV === 'localhost'
          ? SUB_DOMAIN
          : // eslint-disable-next-line no-undef
            window?.location?.hostname
      );
      // setting primary color
      if (res?.pageSequences?.data?.[0]?.primaryColor) {
        dispatch({
          type: 'SET_PRIMARY_COLOR',
          data: res?.pageSequences?.data?.[0]?.primaryColor
        });
        // eslint-disable-next-line no-undef
        await window?.less?.modifyVars({
          '@primary-color': res?.pageSequences?.data?.[0]?.primaryColor
        });
      }
      setPageSequencesSet(true);
    },
    onError: () => {
      dispatch({
        type: 'SET_LOADING',
        data: false
      });
    }
  });

  const [validateLeadId] = useLazyQuery(VALIDATE_LEAD_ID, {
    onCompleted: async (res) => {
      const systemFields = res?.validateLeadId?.systemFields?.reduce(
        (obj, currentObject) => {
          if (currentObject?.fieldType === DYNAMIC_FIELDS_TYPE?.PICK_LIST) {
            return {
              ...obj,
              [currentObject.id]: {
                value: [currentObject?.value?.[0]?.blockId],
                type: currentObject?.fieldType
              }
            };
          }
          return {
            ...obj,
            [currentObject.id]: {
              value: currentObject?.value,
              type: currentObject?.fieldType
            }
          };
        },
        {}
      );

      const leadDataClone = {
        leadId,
        name: res?.validateLeadId?.customerName,
        email: res?.validateLeadId?.email,
        mobileNumber: res?.validateLeadId?.mobileNumber,
        contactMethods: res?.validateLeadId?.contactMethods,
        comments: res?.validateLeadId?.comments,
        zipCode: res?.validateLeadId?.zipCode,
        street: res?.validateLeadId?.location?.addressLine1,
        city: res?.validateLeadId?.location?.city,
        state: res?.validateLeadId?.location?.state,
        lobObject: {
          label: res?.validateLeadId?.lineOfBusiness?.label,
          key: res?.validateLeadId?.lineOfBusiness?.key
        },
        serviceTypeObject: {
          label: res?.validateLeadId?.subArea?.label,
          blockId: res?.validateLeadId?.subArea?.id,
          key: res?.validateLeadId?.subArea?.key
        },
        systemFields: !isEmpty(systemFields) ? { ...systemFields } : null
      };

      setLeadIdData({ ...leadDataClone });
      pageSequences({
        variables: {
          where: {
            slug,
            subDomain:
              process?.env?.REACT_APP_ENV === 'localhost'
                ? SUB_DOMAIN
                : // eslint-disable-next-line no-undef
                  window?.location?.hostname
          }
        }
      });
    },
    onError() {
      dispatch({
        type: 'SET_LOADING',
        data: false
      });
    }
  });

  const [changeQuote] = useMutation(CHANGE_QUOTE, {
    onCompleted: (res) => {
      if (res?.changeQuote) {
        setQuotesData({
          oldPackage: res?.changeQuote?.data?.oldPackage,
          newPackage: res?.changeQuote?.data?.newPackage
        });
        dispatch({
          type: 'SET_LOADING',
          data: false
        });
      }
    },
    onError: () => {
      dispatch({
        type: 'SET_LOADING',
        data: false
      });
      setQuotesData(null);
    }
  });
  useEffect(() => {
    if (pageSequencesData?.length > 0 && pageSequencesSet) {
      setCurrentPage(leadIdData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSequencesData, pageSequencesSet]);

  useEffect(() => {
    // analytics code
    // eslint-disable-next-line no-undef
    if (!window.Wsm.initialized) {
      analytics?.newTracker(process?.env?.REACT_APP_ANALYTICS_URL);
    }
    dispatch({ type: 'SET_SLUG', data: slug });

    const customerLeadID = query?.get('leadId');
    const token = query?.get('token');
    // add condition if leadId present
    if (customerLeadID) {
      // validateLeadId
      setLeadId(customerLeadID);
      validateLeadId({
        variables: {
          where: {
            slug,
            subDomain:
              process?.env?.REACT_APP_ENV === 'localhost'
                ? SUB_DOMAIN
                : // eslint-disable-next-line no-undef
                  window?.location?.hostname,
            leadId: customerLeadID
          }
        }
      });
    } else if (token) {
      changeQuote({
        variables: {
          data: {
            token
          }
        }
      });
    } else {
      pageSequences({
        variables: {
          where: {
            slug,
            isCustomerPortal: true,
            subDomain:
              process?.env?.REACT_APP_ENV === 'localhost'
                ? SUB_DOMAIN
                : // eslint-disable-next-line no-undef
                  window?.location?.hostname
          }
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <Switch>
        <Route exact path={ROUTES?.SLUG} component={Error404} />
        {map(pageSequencesData, (item) => {
          return (
            <Route
              exact
              key={item?.id}
              path={`${ROUTES?.SLUG}/${kebabCase(item?.pageKey)}`}
              render={(props) => (
                <CommonConfigComponent
                  pageSequencesData={pageSequencesData}
                  setPageSequencesData={setPageSequencesData}
                  pageKey={item?.pageKey}
                  {...props}
                />
              )}
            />
          );
        })}
        <Route
          path={`${ROUTES?.SLUG}${ROUTES?.CHANGE_QUOTE}`}
          render={() => <ChangeQuoteWrapper quotesData={quotesData} />}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </>
  );
};

export default withRouter(ContentRoutes);
