import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { Button, Card, Form, message } from 'antd';
import {
  cloneDeep,
  filter,
  find,
  findIndex,
  forEach,
  isArray,
  isEmpty,
  kebabCase,
  map,
  remove,
  sortBy,
  uniqBy
} from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../AppContext';
import {
  CONDITIONS_KEYS,
  DYNAMIC_FIELDS_TYPE,
  SUB_DOMAIN
} from '../common/constants';
import {
  createLeadTracking,
  formatPhoneNumberWithoutMask,
  updateDataMutation,
  uploadPublicFiles
} from '../common/utils';
import history from '../historyData';
import FooterSection from './FooterSection';
import GeneralSection from './GeneralSection';
import LoaderComponent from './LoaderComponent';
import WidgetSection from './WidgetSection';
import { CREATE_LEAD_V2 } from './graphql/Mutation';
import { GET_PAGE_SEQUENCES } from './graphql/Query';
import './styles/CommonConfigComponent.less';

const CommonConfigComponent = ({
  match: { params: { slug = '' } = {} } = {},
  pageSequencesData = [],
  setPageSequencesData,
  pageKey = null
}) => {
  const {
    dispatch,
    state: { storedData, totalProject }
  } = useContext(AppContext);
  const stripe = useStripe();
  const elements = useElements();
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentPageSequence, setCurrentPageSequence] = useState(null);
  const [nextPageObj, setNextPageObj] = useState(null);
  const [savedData, setSavedData] = useState(null);
  const location = useLocation();
  const { pathname = '' } = location;

  const equals = (a, b) => JSON?.stringify(a) === JSON?.stringify(b);

  const [createLeadV2] = useMutation(CREATE_LEAD_V2, {
    onError() {
      setSubmitLoading(false);
    }
  });

  const [secondaryPageSequences] = useLazyQuery(GET_PAGE_SEQUENCES, {
    fetchPolicy: 'network-only',
    onCompleted: async (res) => {
      const secondaryData = map(res?.pageSequences?.data, (item) => {
        return {
          ...item,
          isSecondaryPage: true
        };
      });

      if (secondaryData?.length > 0) {
        const pageSequenceDataCopy = cloneDeep(pageSequencesData)?.filter(
          (item) => !item?.isSecondaryPage
        );

        const projectWidgetIndex = findIndex(
          pageSequenceDataCopy,
          (item) => item?.pageConfiguration?.widgetType === 'PROJECT'
        );
        // adding secondary data after project widget
        pageSequenceDataCopy?.splice(
          projectWidgetIndex + 1,
          0,
          ...secondaryData
        );
        setPageSequencesData(
          map(pageSequenceDataCopy, (item, index) => {
            return {
              ...item,
              order: index + 1
            };
          })
        );
        // eslint-disable-next-line no-undef
        const wsmTracker = window?.Wsm?.getAsyncTracker();
        const visitorId = await wsmTracker?.getVisitorUniqueId();

        const data = {
          ...storedData,
          isSecondaryAdded: true
        };

        await updateDataMutation(data, dispatch, visitorId);
        history?.replace(
          `/${slug}/${kebabCase(
            pageSequenceDataCopy?.[projectWidgetIndex + 1]?.pageKey
          )}`
        );
      } else {
        history?.replace(`/${slug}/${kebabCase(nextPageObj?.pageKey)}`);
      }
    },
    onError: () => {
      dispatch({
        type: 'SET_LOADING',
        data: false
      });
    }
  });

  useEffect(() => {
    async function trackLead() {
      const currentPageSequenceCopy = find(
        pageSequencesData,
        (item) =>
          pathname?.substring(pathname?.lastIndexOf('/') + 1) ===
          kebabCase(item?.pageKey)
      );

      forEach(pageSequencesData, (item, index) => {
        if (currentPageSequenceCopy?.order + 1 === item?.order) {
          if (item?.pageConfiguration?.widgetType === 'CONTACT') {
            setNextPageObj(pageSequencesData?.[index + 1]);
            return;
          }
          setNextPageObj(item);
        }
      });

      // we are not getting primary settings modal data for secondary, so taking that data for secondary questionnaire from project page
      if (currentPageSequenceCopy?.isSecondaryPage) {
        const projectPageConfig = find(
          pageSequencesData,
          (item) => item?.pageConfiguration?.widgetType === 'PROJECT'
        );
        setCurrentPageSequence({
          ...currentPageSequenceCopy,
          brandConfig: projectPageConfig?.brandConfig,
          cutOffTime: projectPageConfig?.cutOffTime,
          tenantLogo: projectPageConfig?.tenantLogo,
          logo: projectPageConfig?.logo,
          primaryColor: projectPageConfig?.primaryColor
        });
      } else {
        setCurrentPageSequence(currentPageSequenceCopy);
      }

      // initialize tracker
      // eslint-disable-next-line no-undef
      const wsmTracker = await window?.Wsm?.getAsyncTracker();

      // eslint-disable-next-line no-undef
      if (window?.Wsm?.initialized) {
        wsmTracker?.setCookiePath(slug);
        wsmTracker?.setPageTitle(currentPageSequenceCopy?.pageKey);
        if (storedData?.zipCode) {
          wsmTracker?.setZipCode(storedData?.zipCode);
        }
        if (storedData?.serviceTypeObject && storedData?.lobObject) {
          const projectWidgetObject = {
            subAreaId: storedData?.serviceTypeObject?.blockId,
            lineOfBusinessKey: storedData?.lobObject?.blockKey
          };
          wsmTracker?.setProjectData(projectWidgetObject);
        }
        wsmTracker?.setSlug(slug);
        wsmTracker?.setSubDomain(
          process?.env?.REACT_APP_ENV === 'localhost'
            ? SUB_DOMAIN
            : // eslint-disable-next-line no-undef
              window?.location?.hostname
        );
        wsmTracker?.setTenantId(currentPageSequenceCopy?.tenantId);
        wsmTracker?.setTrackerUrl(process?.env?.REACT_APP_ANALYTICS_URL);

        // commented as of now pending from backend
        wsmTracker?.trackPageView();
        wsmTracker?.enableHeartBeatTimer();
        wsmTracker?.enableLinkTracking();
      }

      let data = null;

      if (isEmpty(storedData)) {
        const firstPageObj = pageSequencesData?.[0];
        data = {
          currentPage: firstPageObj?.pageKey,
          slug,
          currentOrder: firstPageObj?.order,
          currentQuote: true,
          industryId: firstPageObj?.industryId,
          subDomain:
            process?.env?.REACT_APP_ENV === 'localhost'
              ? SUB_DOMAIN
              : // eslint-disable-next-line no-undef
                window?.location?.hostname,
          ...(location && { ...location?.state?.leadData })
        };
      } else {
        data = {
          ...storedData,
          currentPage: pageKey,
          slug,
          currentOrder: currentPageSequenceCopy?.order
        };
        const projectWidgetObject = {
          subAreaId: storedData?.serviceTypeObject?.blockId,
          lineOfBusinessKey: storedData?.lobObject?.blockKey
        };
        wsmTracker?.setProjectData(projectWidgetObject);
      }
      const visitorId = await wsmTracker?.getVisitorUniqueId();
      await updateDataMutation(data, dispatch, visitorId);
      setLoading(false);
    }
    dispatch({
      type: 'SET_SHOW_SUBMIT_BTN',
      data: true
    });
    trackLead();

    return () => {
      // eslint-disable-next-line no-undef
      const wsmTracker = window?.Wsm?.getAsyncTracker();
      wsmTracker?.disableHeartBeatTimer();
      wsmTracker?.disableLinkTracking();
      wsmTracker?.removeAllTrackers();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitCta = async (values) => {
    setSubmitLoading(true);

    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();

    let finalSystemFields = {};
    let finalCustomFields = {};
    const filesToSend = {};
    const allowedPages = [];
    const customUploadWidget =
      values?.customFields?.[currentPageSequence?.id]?.fileList;

    const action = `Customer provided information for ${currentPageSequence?.pageConfiguration?.title} page`;

    if (values?.systemFields) {
      map(values?.systemFields, (item, key) => {
        if (item?.NUMBER) {
          finalSystemFields = {
            ...finalSystemFields,
            [key]: {
              type: DYNAMIC_FIELDS_TYPE?.NUMBER,
              value: [`${item?.NUMBER?.value}`],
              pageId: currentPageSequence?.id
            }
          };
        }
        if (item?.[DYNAMIC_FIELDS_TYPE?.TEXT]) {
          finalSystemFields = {
            ...finalSystemFields,
            [key]: {
              type: DYNAMIC_FIELDS_TYPE?.TEXT,
              value: [item?.[DYNAMIC_FIELDS_TYPE?.TEXT]?.value],
              pageId: currentPageSequence?.id
            }
          };
        }
        if (item?.[DYNAMIC_FIELDS_TYPE?.PICK_LIST]) {
          let value;
          const checkForArray = Array?.isArray(
            item?.[DYNAMIC_FIELDS_TYPE?.PICK_LIST]?.value
          );
          if (checkForArray) {
            const aryLength = item?.[
              DYNAMIC_FIELDS_TYPE?.PICK_LIST
            ]?.value?.filter((pickListItem) => pickListItem)?.length;

            if (aryLength > 0) {
              value = [...item?.[DYNAMIC_FIELDS_TYPE?.PICK_LIST]?.value];
            }
          } else if (item?.[DYNAMIC_FIELDS_TYPE?.PICK_LIST]?.value) {
            value = [item?.[DYNAMIC_FIELDS_TYPE?.PICK_LIST]?.value];
          } else {
            value = [];
          }
          finalSystemFields = {
            ...finalSystemFields,
            [key]: {
              type: DYNAMIC_FIELDS_TYPE?.PICK_LIST,
              value,
              pageId: currentPageSequence?.id
            }
          };
        }
      });
    }

    if (values?.customFields) {
      map(values?.customFields, (item, key) => {
        let value;
        if (item) {
          value = isArray(item) ? item : [item];
        } else {
          value = [];
        }
        finalCustomFields = {
          ...finalCustomFields,
          [key]: value
        };
      });
    }

    // This is due to possibility of it coming as an array and an object(older records) as well
    if (customUploadWidget?.length > 0 && customUploadWidget) {
      const filesToUpload = [];
      const uploadedFiles = [];
      map(customUploadWidget, (file) => {
        if (!file?.url) {
          filesToUpload?.push(file);
        } else {
          uploadedFiles?.push(file);
        }
      });

      if (filesToUpload?.length > 0) {
        await Promise?.all([
          uploadPublicFiles({
            files: filesToUpload,
            slug,
            tenantId: currentPageSequence?.tenantId,
            fieldKey: currentPageSequence?.pageConfiguration?.id
          })
        ]).then((res) => {
          if (!isEmpty(res?.[0])) {
            filesToSend[currentPageSequence?.id] = [
              ...uploadedFiles,
              ...res?.[0]
            ];
          }
        });
      } else if (uploadedFiles?.length > 0) {
        filesToSend[currentPageSequence?.id] = [...uploadedFiles];
      }
    }

    if (
      currentPageSequence?.pageConfiguration?.widgetType === 'APPOINTMENT' ||
      currentPageSequence?.pageConfiguration?.widgetType === 'CONTACT'
    ) {
      setSubmitLoading(true);
      let paymentMethodId;
      if (savedData?.selectedStep === 1) {
        if (!stripe || !elements) {
          setSubmitLoading(false);
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        const result = await stripe?.createPaymentMethod({
          type: 'card',
          card: elements?.getElement(CardNumberElement),
          billing_details: {
            name: values?.cardHolderName,
            email: values?.cardHolderEmail
          }
        });
        if (result?.error) {
          // Show error in payment form
          message?.error(result?.error?.message);
          setSubmitLoading(false);
          return;
        }
        paymentMethodId = result?.paymentMethod?.id;
      }
      const quoteDetails = [];
      forEach(storedData, (item) => {
        const itemCloneObj = { ...item };
        if (savedData?.lobObject) {
          if (itemCloneObj?.lobObject?.key !== savedData?.lobObject?.key) {
            itemCloneObj.dimension = null;
          }
        }
        if (item?.productTitle) {
          quoteDetails?.push({
            lobObject: item?.lobObject,
            navigateTo: item?.navigateTo,
            productDescription: item?.productDescription,
            productId: item?.productId,
            productImageUrl: item?.productImageUrl,
            productItemId: item?.productItemId,
            productPrice: item?.productPrice,
            productTitle: item?.productTitle,
            products: item?.products,
            quoteVariant: item?.quoteVariant,
            selectedQuoteTitle: item?.selectedTab,
            serviceTypeObject: item?.serviceTypeObject,
            systemFields: {
              ...itemCloneObj?.systemFields,
              ...savedData?.systemFields,
              ...finalSystemFields
            },
            customFields: {
              ...itemCloneObj?.customFields,
              ...savedData?.customFields,
              ...finalCustomFields,
              ...filesToSend
            }
          });
        }
      });
      const finalDataObj = {
        name: values?.name,
        email: values?.email,
        leadSource: 'ROQ',
        industryId: storedData?.industryId,
        comments: values?.comments,
        mobileNumber: formatPhoneNumberWithoutMask(values?.mobileNumber),
        slug: storedData?.slug,
        subDomain: storedData?.subDomain,
        ...(storedData?.leadId && { leadId: storedData?.leadId }),
        location: {
          addressLine1: savedData?.street,
          city: storedData?.city,
          state: storedData?.state,
          country: 'USA',
          zipCode: storedData?.zipCode
        },
        pageLevel: currentPageSequence?.pageConfiguration?.widgetType,
        visitorId,

        // only send data from contact page
        ...(currentPageSequence?.pageConfiguration?.widgetType ===
          'CONTACT' && {
          emailMeInfo: {
            urgent: values?.urgent,
            emailConfirmation: values?.emailConfirmation
          },
          contactMeInfo: {
            createdAt: moment(),
            timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone
          }
        }),

        // only send data from appointment page
        ...(currentPageSequence?.pageConfiguration?.widgetType ===
          'APPOINTMENT' && {
          paymentMethodId,
          scheduleTime: {
            date: savedData?.date,
            timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
            startTime: savedData?.selectedTime?.from,
            endTime: savedData?.selectedTime?.to
          },
          contactMethods: values?.contactMethods
        })
      };

      if (storedData?.leadId) {
        createLeadTracking(
          slug,
          pageKey,
          visitorId,
          storedData?.leadId,
          action,
          finalDataObj
        );
      }

      const response = await createLeadV2({
        variables: { data: finalDataObj }
      });
      if (response?.data?.createLeadV2) {
        setSubmitLoading(false);
        history?.replace(`/${slug}/${kebabCase(nextPageObj?.pageKey)}`);
      }
      return;
    }

    const currentPageData = {
      id: currentPageSequence?.id,
      order: currentPageSequence?.order,
      pageKey: currentPageSequence?.pageKey,
      title: currentPageSequence?.title
    };
    allowedPages?.push(currentPageData);
    const isSecondaryAdded = storedData?.isSecondaryAdded;
    const dataCloneObj = { ...storedData };
    if (savedData?.lobObject) {
      if (dataCloneObj?.lobObject?.key !== savedData?.lobObject?.key) {
        dataCloneObj.dimension = null;
      }
    }

    const data = {
      ...dataCloneObj,
      ...savedData,
      ...(values && { ...values }),
      systemFields: {
        ...dataCloneObj?.systemFields,
        ...savedData?.systemFields,
        ...finalSystemFields
      },
      customFields: {
        ...dataCloneObj?.customFields,
        ...savedData?.customFields,
        ...finalCustomFields,
        ...filesToSend
      },
      previousPageKey: currentPageSequence?.pageKey,
      previousPageTitle: currentPageSequence?.title,
      allowedPages: dataCloneObj?.allowedPages
        ? sortBy(
            uniqBy([...dataCloneObj?.allowedPages, ...allowedPages], 'id'),
            (items) => items?.order
          )
        : sortBy(uniqBy([...allowedPages], 'id'), (items) => items?.order)
    };
    if (storedData?.leadId) {
      createLeadTracking(slug, pageKey, visitorId, storedData?.leadId, action);
    }

    await updateDataMutation(data, dispatch, visitorId);
    let nextPageKey = nextPageObj?.pageKey;
    const findPages = filter(
      pageSequencesData,
      (item) => !item?.pageConfiguration?.skipPageConditions?.alwaysSkip
    );

    const findNextPages = findIndex(
      findPages,
      (o) => o?.id === currentPageSequence?.id
    );
    const nextPage = findPages?.[findNextPages + 1];
    nextPageKey = nextPage?.pageKey;
    setNextPageObj(nextPage);
    const getNextPageConditions = filter(
      isArray(nextPage?.pageConfiguration?.skipPageConditions)
        ? nextPage?.pageConfiguration?.skipPageConditions
        : nextPage?.pageConfiguration?.skipPageConditions?.data,
      (item) => item?.check
    );
    if (!isEmpty(getNextPageConditions)) {
      const getPagesIds = map(
        getNextPageConditions,
        (item) => item?.identifier
      );
      const getPagesValues = {};
      if (!isEmpty(getPagesIds)) {
        if (!isEmpty(data?.customFields)) {
          map(getPagesIds, (id) => {
            if (data?.customFields?.[id]) {
              getPagesValues[id] = data?.customFields?.[id];
            }
          });
        }
        if (!isEmpty(data?.systemFields)) {
          map(data?.systemFields, (field) => {
            if (getPagesIds?.includes(field?.pageId)) {
              getPagesValues[field?.pageId] = field?.value;
            }
          });
        }
      }

      const skipPage = map(getNextPageConditions, (item) => {
        const pageValue = getPagesValues?.[item?.identifier];
        if (pageValue) {
          if (item?.condition === CONDITIONS_KEYS?.EQUAL_TO) {
            if (equals(pageValue, item?.value)) {
              return true;
            }
          }
          if (item?.condition === CONDITIONS_KEYS?.NOT_EQUAL_TO) {
            if (!equals(pageValue, item?.value)) {
              return true;
            }
          }
          if (item?.condition === CONDITIONS_KEYS?.CONTAINS) {
            if (item?.type === DYNAMIC_FIELDS_TYPE?.PICK_LIST) {
              const doesConditionMatch = map(pageValue, (pageItem) => {
                if (item?.value?.includes(pageItem)) {
                  return true;
                }
                return false;
              });

              if (doesConditionMatch?.includes(true)) {
                return true;
              }
            } else if (pageValue?.[0]?.includes(item?.value?.[0])) {
              return true;
            }
          }
          if (item?.condition === CONDITIONS_KEYS?.NOT_CONTAINS) {
            if (item?.type === DYNAMIC_FIELDS_TYPE?.PICK_LIST) {
              const doesConditionMatch = map(pageValue, (pageItem) => {
                if (item?.value?.includes(pageItem)) {
                  return true;
                }
                return false;
              });
              if (doesConditionMatch?.includes(true)) {
                return false;
              }
              return true;
            }
            if (!pageValue?.[0]?.includes(item?.value?.[0])) {
              return true;
            }
          }
          if (item?.condition === CONDITIONS_KEYS?.GREATER_LESS_RANGE) {
            if (item?.greaterThan?.check && item?.lessThan?.check) {
              if (
                pageValue?.[0] >= item?.greaterThan?.value &&
                pageValue?.[0] <= item?.lessThan?.value
              ) {
                return true;
              }
            } else if (item?.greaterThan?.check) {
              if (pageValue?.[0] > item?.greaterThan?.value) {
                return true;
              }
            } else if (item?.lessThan?.check) {
              if (pageValue?.[0] < item?.lessThan?.value) {
                return true;
              }
            }
          }
        }
        return false;
      });
      if (!skipPage?.includes(false)) {
        const findNextPage = findIndex(
          findPages,
          (o) => o?.id === nextPage?.id
        );
        if (findNextPage !== -1) {
          setNextPageObj(findPages?.[findNextPage + 1]);
          nextPageKey = findPages?.[findNextPage + 1]?.pageKey;
        }
        const updatedAllowedPages = uniqBy(
          [...storedData?.allowedPages, ...allowedPages],
          'id'
        );
        remove(updatedAllowedPages, (o) => o?.id === nextPage?.id);
        const allSystemFields = {
          ...storedData?.systemFields,
          ...savedData?.systemFields,
          ...finalSystemFields
        };

        forEach(allSystemFields, (item, key) => {
          const systemPageId = item?.pageId;
          const allowed = updatedAllowedPages?.find(
            (allowedPage) => allowedPage?.id === systemPageId
          );
          if (allowed) {
            finalSystemFields = {
              ...finalSystemFields,
              [key]: {
                ...item
              }
            };
          }
        });

        const updatedData = {
          ...storedData,
          ...savedData,
          ...(values && { ...values }),
          systemFields: {
            ...finalSystemFields
          },
          customFields: {
            ...storedData?.customFields,
            ...savedData?.customFields,
            ...finalCustomFields,
            ...filesToSend
          },
          previousPageKey: currentPageSequence?.pageKey,
          previousPageTitle: currentPageSequence?.title,
          allowedPages: updatedAllowedPages
        };
        await updateDataMutation(updatedData, dispatch, visitorId);
      }
    }

    if (currentPageSequence?.pageConfiguration?.widgetType === 'PROJECT') {
      if (
        savedData?.navigateTo &&
        (storedData?.navigateTo !== savedData?.navigateTo || !isSecondaryAdded)
      ) {
        // check if user changed his subArea also that selected sub area has attached secondary questionnaire with it.
        try {
          const secondaryId = savedData?.navigateTo;
          secondaryPageSequences({
            variables: {
              where: {
                id: secondaryId,
                isCustomerPortal: true
              }
            }
          });
        } catch (error) {
          setSubmitLoading(false);
          return error;
        }
        return;
      }
      if (isSecondaryAdded && savedData?.navigateTo) {
        // execute below code if user doesn't change subarea.
        setSubmitLoading(false);
        history?.replace(`/${slug}/${kebabCase(nextPageKey)}`);
      } else {
        // selected service type has not attached any secondary questionnaire so we are removing all ready attached secondary pages.
        const pageSequenceDataCopy = cloneDeep(pageSequencesData)?.filter(
          (item) => !item?.isSecondaryPage
        );

        const projectWidgetIndex = findIndex(
          pageSequenceDataCopy,
          (item) => item?.pageConfiguration?.widgetType === 'PROJECT'
        );

        setPageSequencesData(
          map(pageSequenceDataCopy, (item, index) => {
            return {
              ...item,
              order: index + 1
            };
          })
        );
        setNextPageObj(pageSequenceDataCopy?.[projectWidgetIndex + 1]);
        history?.replace(
          `/${slug}/${kebabCase(
            pageSequenceDataCopy?.[projectWidgetIndex + 1]?.pageKey
          )}`
        );
        return;
      }
    }
    setSubmitLoading(false);
    history?.replace(`/${slug}/${kebabCase(nextPageKey)}`);
  };

  const handleBack = async () => {
    const currentQuoteObj = { ...storedData };
    const allowedPreviousPageIndex = findIndex(
      currentQuoteObj?.allowedPages,
      (item) => item?.pageKey === currentQuoteObj?.previousPageKey
    );
    let data;

    if (allowedPreviousPageIndex < 0) {
      const previousPageIndex = findIndex(
        pageSequencesData,
        (item) => item?.pageKey === currentQuoteObj?.previousPageKey
      );
      data = {
        ...currentQuoteObj,
        previousPageKey: pageSequencesData?.[previousPageIndex - 1]?.pageKey,
        previousPageTitle: pageSequencesData?.[previousPageIndex - 1]?.title
      };
    } else {
      data = {
        ...currentQuoteObj,
        previousPageKey:
          currentQuoteObj?.allowedPages?.[allowedPreviousPageIndex - 1]
            ?.pageKey,
        previousPageTitle:
          currentQuoteObj?.allowedPages?.[allowedPreviousPageIndex - 1]?.title
      };
    }
    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();

    const action = `Customer went back to the ${currentQuoteObj?.previousPageTitle} page`;

    if (storedData?.leadId) {
      createLeadTracking(slug, pageKey, visitorId, storedData?.leadId, action);
    }
    await updateDataMutation(data, dispatch, visitorId);

    history?.replace(`/${slug}/${kebabCase(storedData?.previousPageKey)}`);
  };

  const handleRedirectToContact = async () => {
    if (currentPageSequence?.pageConfiguration?.widgetType === 'CONTACT') {
      return;
    }
    const contactMePge = find(
      pageSequencesData,
      (item) => item?.pageConfiguration?.widgetType === 'CONTACT'
    );
    const allowedPages =
      storedData?.allowedPages?.length > 0 ? [...storedData?.allowedPages] : [];
    const currentPageData = {
      id: currentPageSequence?.id,
      order: currentPageSequence?.order,
      pageKey: currentPageSequence?.pageKey,
      title: currentPageSequence?.title
    };
    allowedPages?.push(currentPageData);
    const data = {
      ...storedData,
      allowedPages,
      previousPageKey: currentPageSequence?.pageKey,
      previousPageTitle: currentPageSequence?.title
    };

    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();
    const action = `Customer went to the contact page from ${currentPageSequence?.title} page`;

    if (storedData?.leadId) {
      createLeadTracking(slug, pageKey, visitorId, storedData?.leadId, action);
    }
    await updateDataMutation(data, dispatch, visitorId);
    history?.replace(`/${slug}/${kebabCase(contactMePge?.pageKey)}`);
  };

  const handleClickCart = async () => {
    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();
    // need project data
    if (
      currentPageSequence?.pageConfiguration?.widgetType === 'CART' ||
      totalProject < 1
    ) {
      return;
    }

    const action = `Customer went to the cart page from ${currentPageSequence?.title} page`;
    if (storedData?.leadId) {
      createLeadTracking(slug, pageKey, visitorId, storedData?.leadId, action);
    }
    const cartPage = find(
      pageSequencesData,
      (item) => item?.pageConfiguration?.widgetType === 'CART'
    );
    const data = {
      ...storedData,
      previousPageKey: currentPageSequence?.pageKey,
      previousPageTitle: currentPageSequence?.title
    };

    await updateDataMutation(data, dispatch, visitorId);

    history?.replace(`/${slug}/${kebabCase(cartPage?.pageKey)}`);
  };

  const handleBackHome = () => {
    // eslint-disable-next-line no-undef
    const wsmTracker = window?.Wsm?.getAsyncTracker();
    if (wsmTracker) {
      wsmTracker?.deleteCookies();
      wsmTracker?.removeAllTrackers();
    }
    const firstPageObj = pageSequencesData?.[0];
    history?.replace(`/${slug}/${kebabCase(firstPageObj?.pageKey)}`);
    // eslint-disable-next-line no-undef
    window?.location?.reload();
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div className="common-config-component">
      <Card className="full-height-card card-body-padding horizontal-padding">
        <Form
          layout="vertical"
          autoComplete="off"
          name="common-form"
          form={form}
          onFinish={handleSubmitCta}
        >
          <GeneralSection
            pageConfiguration={currentPageSequence?.pageConfiguration}
            currentPageSequence={currentPageSequence}
            globalHeaderImage={
              currentPageSequence?.brandConfig?.headerImage?.url
            }
          />
          <WidgetSection
            pageConfiguration={currentPageSequence?.pageConfiguration}
            pageKey={pageKey}
            handleSubmitCta={handleSubmitCta}
            setSavedData={setSavedData}
            nextPageObj={nextPageObj}
            pageSequencesData={pageSequencesData}
            form={form}
            currentPageSequence={currentPageSequence}
            savedData={savedData}
            fieldConfig={currentPageSequence?.fieldConfig}
            defaultPickListOption={currentPageSequence?.defaultPickListOption}
          />
          {nextPageObj ? (
            <FooterSection
              currentPageSequence={currentPageSequence}
              submitLoading={submitLoading}
              handleBack={handleBack}
              handleRedirectToContact={handleRedirectToContact}
              handleClickCart={handleClickCart}
              pageSequencesData={pageSequencesData}
            />
          ) : (
            <div className="mt-16">
              <Button
                onClick={handleBackHome}
                className="common-button mb-16"
                id="home-btn"
                type="primary"
              >
                Back to Homepage
              </Button>
            </div>
          )}
        </Form>
      </Card>
    </div>
  );
};

export default CommonConfigComponent;
