import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import DecimalNumberComponent from '../DecimalNumberComponent';

const RenderCustomNumber = ({
  widgetConfig = null,
  pageId = null,
  form = null,
  editData = null
}) => {
  const label = widgetConfig?.config?.label;
  const toolTipCheck = widgetConfig?.config?.tooltip;
  const toolTipValue = widgetConfig?.config?.tooltipValue;
  const required = widgetConfig?.config?.rules?.required;
  const hideLabel = widgetConfig?.config?.rules?.hideLabel;
  const readOnly = widgetConfig?.config?.rules?.readOnly;
  const defaultCheck = widgetConfig?.config?.defaultCheck;
  const defaultValue = widgetConfig?.config?.default;
  const minNumber = widgetConfig?.config?.range?.minNumber;
  const maxNumber = widgetConfig?.config?.range?.maxNumber;
  const decimalPlace = widgetConfig?.config?.decimalPlace;

  useEffect(() => {
    if (!isEmpty(editData?.[pageId])) {
      form?.setFieldsValue({
        customFields: {
          [pageId]: editData?.[pageId]?.[0]
        }
      });
    } else if (defaultCheck) {
      form?.setFieldsValue({
        customFields: {
          [pageId]: `${defaultValue}`
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, defaultCheck, editData]);

  return (
    <div>
      <Form.Item
        name={['customFields', pageId]}
        label={hideLabel ? ' ' : label}
        tooltip={
          toolTipCheck && {
            title: toolTipValue,
            icon: <InfoCircleOutlined />
          }
        }
        rules={[
          { required, message: 'This field is required. Please fill it in.' },
          () => ({
            validator(_, value) {
              if (value) {
                const convertedToInt = parseFloat(value, 10);
                if (minNumber && maxNumber) {
                  if (
                    !(
                      convertedToInt >= minNumber && convertedToInt <= maxNumber
                    )
                  ) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject(
                      `should be between ${minNumber} to ${maxNumber}`
                    );
                  }
                } else if (minNumber && !maxNumber) {
                  if (!(convertedToInt >= minNumber)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject(
                      `should be greater than ${minNumber}`
                    );
                  }
                } else if (maxNumber && !minNumber) {
                  if (!(convertedToInt <= maxNumber)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject(`should be less than ${maxNumber}`);
                  }
                }
              }
              return Promise?.resolve();
            }
          })
        ]}
        className={`${toolTipCheck && 'label-with-tooltip'}`}
        normalize={(value) => `${value}`}
      >
        <DecimalNumberComponent
          name="Number"
          disabled={readOnly}
          decimalValue={decimalPlace}
        />
      </Form.Item>
    </div>
  );
};

export default RenderCustomNumber;
