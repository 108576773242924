import { message } from 'antd';
import { every, find, forEach, isEmpty, kebabCase, map } from 'lodash';
import client from '../apollo';
import {
  CREATE_LEAD_STORE_DATA,
  CREATE_LEAD_TRACKING,
  GET_PUBLIC_SIGN_URL
} from '../components/graphql/Mutation';
import history from '../historyData';
import api from './api';
import { READ, REGEX, SUB_DOMAIN, WRITE } from './constants';

// Portal related methods
export const injectUsingPortal = (portalId) => {
  // eslint-disable-next-line no-undef
  return document?.getElementById(portalId);
};

export const isPortalIdExists = (portalId) => {
  return !!injectUsingPortal(portalId);
};

export const refreshGrid = () => {
  // eslint-disable-next-line no-undef
  window?.dispatchEvent(new Event('refresh-grid'));
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
    whitespace: true
  },
  requiredWithoutMessage: {
    required: true,
    message: '',
    whitespace: true
  },
  requiredWhiteSpaceAllowed: {
    required: true,
    message: 'Required'
  },
  slug: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (REGEX?.SLUG?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please Enter a valid domain name!');
      }
      return Promise?.resolve();
    }
  }),
  email: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid E-mail!');
      }
      return Promise?.resolve();
    }
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    }
  }),
  zipCode: () => ({
    validator(rule, value) {
      if (!value) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please Enter Postal Code');
      }
      if (value?.length < 3 || value?.length > 5 || parseInt(value, 10) === 0) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Postal Code');
      }
      return Promise?.resolve();
    }
  }),
  url: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.WEB_URL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid URL');
      }
      return Promise?.resolve();
    }
  }),
  color: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.COLOR?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Color Code');
      }
      return Promise?.resolve();
    }
  }),
  startDate: (endDate) => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (endDate && value > endDate) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Start date cannot be greater than end date!');
      }
      return Promise?.resolve();
    }
  }),
  endDate: (startDate) => ({
    validator(rule, value) {
      if (!value || !startDate) {
        return Promise?.resolve();
      }
      if (startDate && value < startDate) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Expiry date cannot be less than start date!');
      }
      return Promise?.resolve();
    }
  }),
  dynamicFieldsUpload: ({
    fieldRequired = false,
    fileValue = [],
    maxFile = 10,
    size = 50,
    limitFileSize = false
  }) => ({
    type: 'array',
    required: fieldRequired,
    validator(rule, value) {
      const intialValue = value?.fileList || value;
      const isSameType = every(intialValue, (item) => {
        const { name = '' } = item;
        const ext = name?.substring(name?.lastIndexOf('.') + 1);
        const newValue = fileValue?.includes('JPEG')
          ? [...fileValue, 'JPG']
          : fileValue;
        return newValue?.includes(ext?.toUpperCase());
      });

      if (!isSameType) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(
          `File(s) must be of type ${fileValue?.join(', ')?.toLowerCase()}`
        );
      }
      if (intialValue?.length > maxFile) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(`Max ${maxFile} File(s) are allowed!`);
      }
      let totalSize = 0;
      forEach(intialValue, (item) => {
        totalSize += item?.size;
      });
      const isLt2M = totalSize / 1024 / 1024 < (limitFileSize ? size : 50);
      if (!isLt2M) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(`File(s) must be smaller than ${size} MB!`);
      }
      if (fieldRequired && !intialValue?.length) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please Upload File(s)');
      }
      return Promise?.resolve();
    }
  })
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj.setUTCHours(timeObj.getUTCHours());
  formattedDateTime = new Date(formattedDateTime).setUTCMinutes(
    timeObj.getUTCMinutes()
  );
  formattedDateTime = new Date(formattedDateTime).toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');

  if (cleaned?.length > 10) {
    const match = cleaned?.match(/^(\d)(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[2]}) ${match[3]}-${match[4]}`;
    }
  } else {
    // Check if the input is of correct length
    const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice)?.toLocaleString('en', {
    style: 'currency',
    currency: 'USD'
  });
};

export const minutesOfDay = (date) => {
  return date?.minutes() + date?.hours() * 60;
};

export const formatUnits = (unit) => {
  const formatedUnit = unit || 0;

  return Number(formatedUnit)?.toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export const formItemProps = { normalize: (value) => value?.trim() };

export const displayZipCodes = (value) => {
  if (value?.length === 3) {
    return `00${value}`;
  }
  if (value?.length === 4) {
    return `0${value}`;
  }
  return value;
};

export const generateEmbeddedUrl = (url) => {
  if (url) {
    if (url?.includes('embed')) {
      const res = url?.split('/');
      const autoPlaEmbedUrl = `${url}?playlist=${res?.[4]}&loop=1&controls=0`;
      return autoPlaEmbedUrl;
    }
    if (url?.includes('=')) {
      const res = url?.split('=');
      const embeddedUrl = `https://www.youtube.com/embed/${res?.[1]}?playlist=${res?.[1]}&loop=1&controls=0`;

      return embeddedUrl;
    }
    const res = url?.split('/');
    const embeddedUrl = `https://www.youtube.com/embed/${res?.[3]}?playlist=${res?.[3]}&loop=1&controls=0`;
    return embeddedUrl;
  }
  return 'https://www.youtube.com';
};

export const updateDataMutation = async (
  data = null,
  dispatch,
  visitorId = null
) => {
  try {
    await client
      ?.mutate({
        mutation: CREATE_LEAD_STORE_DATA,
        variables: {
          data: {
            data,
            visitorId
          },
          ...(data?.id && { where: { id: data?.id } })
        }
      })
      .then((res) => {
        if (res?.data?.createLeadStoreData?.id) {
          dispatch({
            type: 'SET_STORED_DATA',
            data: { ...data, id: res?.data?.createLeadStoreData?.id }
          });
        } else {
          dispatch({ type: 'SET_STORED_DATA', data });
        }
      });
  } catch (error) {
    return error;
  }
};

export const getCurrentQuoteObject = (quoteData) => {
  return find(quoteData, (item) => item?.data?.currentQuote);
};

export const createLeadTracking = (
  slug = '',
  pageLevel = '',
  visitorId = null,
  leadId = null,
  action = '',
  data = {}
) => {
  client
    ?.mutate({
      mutation: CREATE_LEAD_TRACKING,
      variables: {
        data: {
          pageLevel,
          visitorId,
          leadId,
          questionnaire: {
            slug,
            subDomain:
              process?.env?.REACT_APP_ENV === 'localhost'
                ? SUB_DOMAIN
                : // eslint-disable-next-line no-undef
                  window?.location?.hostname
          },
          action,
          ...(data && { actionData: data })
        }
      }
    })
    .catch((e) => {
      message?.error(e?.message);
    });
};

export const fetchStep = (slug = '', currentPage = '', dispatch) => {
  dispatch({
    type: 'SET_LOADING',
    data: false
  });
  history?.replace(`/${slug}/${kebabCase(currentPage)}`);
};

export const strippedString = (string) => {
  return string?.replace(/(<([^>]+)>)/gi, '');
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });
};

export const uploadPublicFiles = async ({
  files = [],
  slug = '',
  tenantId = 0,
  fieldKey = ''
}) => {
  if (files?.length > 0) {
    try {
      const signVariables = await Promise?.all(
        map(files, async (item) => {
          const { name = '', type = '' } = item;
          const ext = name?.substring(name?.lastIndexOf('.') + 1);
          const timestamp = Date?.now();
          const filename = name?.split('.')?.slice(0, -1)?.join('.');
          const newFilename = `${timestamp}_${filename}.${ext}`;
          const key = `organization/${tenantId}/assets/${slug}_${fieldKey}_${newFilename}`;
          return {
            extension: `.${ext}`,
            contentType: type,
            key
          };
        })
      );

      if (signVariables) {
        const data = [...signVariables];
        const res = await client?.mutate({
          mutation: GET_PUBLIC_SIGN_URL,
          variables: {
            action: WRITE,
            data
          }
        });

        if (res?.data?.generatePublicSignedUrl) {
          try {
            const finalResponse = await Promise?.all(
              map(
                res?.data?.generatePublicSignedUrl,
                async (signUrl, index) => {
                  const {
                    key,
                    extension = '',
                    contentType = ''
                  } = signVariables?.[index];
                  const {
                    name = '',
                    size = null,
                    originFileObj = null
                  } = files?.[index];

                  const response = await api(signUrl?.signedRequest, {
                    method: 'PUT',
                    data: originFileObj,
                    headers: {
                      'Content-Type': contentType
                    }
                  });
                  if (response) {
                    const logo = {
                      url: signUrl?.url,
                      name,
                      key,
                      extension,
                      contentType,
                      size: size / 1024 / 1024
                    };
                    return logo;
                  }
                }
              )
            );
            return finalResponse;
          } catch (error) {
            message?.error(error?.message);
          }
        }
      }
    } catch (error) {
      message?.error(error?.message);
    }
  }
};

export const getPublicUrl = async ({ logoObject }) => {
  const initialArray = logoObject?.fileList || logoObject;
  if (!isEmpty(initialArray)) {
    const imageArray = initialArray?.map((item) => {
      return {
        extension: item?.extension,
        contentType: item?.contentType,
        key: item?.key
      };
    });

    try {
      if (imageArray?.length > 0) {
        const res = await client?.mutate({
          mutation: GET_PUBLIC_SIGN_URL,
          variables: {
            action: READ,
            data: imageArray
          }
        });

        if (res?.data?.generatePublicSignedUrl) {
          return initialArray?.map((item) => {
            return {
              ...item,
              url: res?.data?.generatePublicSignedUrl?.find(
                (items) => items?.key === item?.key
              )?.signedRequest
            };
          });
        }
      }
    } catch (error) {
      message?.error(error?.message);
    }
  }
};

export const calculateVolume = (area, depth) => {
  const finalVolume = area * depth;
  return finalVolume?.toFixed(2);
};

export const calculateNoOfSteps = (totalHeight, height) => {
  return Math?.round(totalHeight / height);
};

export const calculateTotalVolume = (width, height, depth, noOfSteps) => {
  const finalVolume = width * height * depth * noOfSteps;
  return finalVolume?.toFixed(2);
};

export const isMobileDevice = () => {
  // eslint-disable-next-line no-undef
  return /iPhone|iPad|iPod|Android|Windows Phone/i?.test(navigator?.userAgent);
};
