import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DYNAMIC_FIELDS_TYPE } from '../../common/constants';
import BooleanChecked from '../BooleanChecked';
import BooleanUnchecked from '../BooleanUnchecked';

const RenderSystemBoolean = ({
  widgetConfig = null,
  setSavedData,
  editData = null,
  savedData = null,
  currentPageSequence = {}
}) => {
  const trueValue = widgetConfig?.trueValue;
  const falseValue = widgetConfig?.falseValue;
  const booleanDefaultValue = widgetConfig?.booleanDefaultValue;
  const fieldId = widgetConfig?.selectedSystemFieldBlock?.selectedSystemFieldId;
  const label = widgetConfig?.label;
  const toolTipChecked = widgetConfig?.tooltip;
  const toolTipValue = widgetConfig?.tooltipValue;
  const required = widgetConfig?.required;
  const [selectedCard, setSelectedCard] = useState(booleanDefaultValue);

  useEffect(() => {
    if (editData) {
      if (!isEmpty(editData?.[fieldId]?.value)) {
        setSelectedCard(`${editData?.[fieldId]?.value?.[0]}`);
      } else {
        setSelectedCard(`${booleanDefaultValue}`);
      }
    } else {
      setSelectedCard(`${booleanDefaultValue}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, booleanDefaultValue]);

  useEffect(() => {
    setSavedData({
      systemFields: {
        ...savedData?.systemFields,
        [fieldId]: {
          type: DYNAMIC_FIELDS_TYPE?.BOOLEAN,
          value: [`${selectedCard}`],
          pageId: currentPageSequence?.id
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCard]);

  return (
    <div className="boolean-component">
      <div className="custom-form-item">
        <div className="item-label">{label}</div>
        {required && <span className="required-mark">*</span>}
        {toolTipChecked && (
          <Tooltip title={toolTipValue}>
            <InfoCircleOutlined className="custom-tooltip" />
          </Tooltip>
        )}
      </div>
      <Card bordered={false} className="tile-grid mt-4">
        <Card.Grid
          className={`grid-card tile-view ${
            selectedCard === 'false' ? 'card-selected' : ''
          }`}
          hoverable
          onClick={() => {
            setSelectedCard('false');
          }}
        >
          <div className="d-flex flex-vertical align-center">
            <BooleanUnchecked
              selected={selectedCard === 'false'}
              primaryColor={currentPageSequence?.primaryColor}
            />
            <span className="card-title" title={falseValue}>
              {falseValue}
            </span>
          </div>
        </Card.Grid>
        <Card.Grid
          className={`grid-card tile-view ${
            selectedCard === 'true' ? 'card-selected' : ''
          }`}
          hoverable
          onClick={() => {
            setSelectedCard('true');
          }}
        >
          <div className="d-flex flex-vertical align-center">
            <BooleanChecked
              selected={selectedCard === 'true'}
              primaryColor={currentPageSequence?.primaryColor}
            />
            <span className="card-title break-word" title={trueValue}>
              {trueValue}
            </span>
          </div>
        </Card.Grid>
      </Card>
    </div>
  );
};

export default RenderSystemBoolean;
