import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { DYNAMIC_FIELDS_TYPE } from '../../common/constants';
import { strippedString } from '../../common/utils';
import EditorComponent from '../EditorComponent';
import InputComponent from '../InputComponent';

const RenderSystemText = ({
  widgetConfig = null,
  editData = null,
  fieldConfig = null,
  form = null
}) => {
  const fieldId = widgetConfig?.selectedSystemFieldBlock?.selectedSystemFieldId;
  const label = widgetConfig?.label;
  const required = widgetConfig?.required;
  const toolTipCheck = widgetConfig?.toolTipCheck;
  const toolTipText = widgetConfig?.toolTipText;
  const textType = fieldConfig?.[DYNAMIC_FIELDS_TYPE?.TEXT]?.textType;
  const maxLength =
    fieldConfig?.[DYNAMIC_FIELDS_TYPE?.TEXT]?.textLength?.maxLength;
  const minLength =
    fieldConfig?.[DYNAMIC_FIELDS_TYPE?.TEXT]?.textLength?.minLength;

  const defaultValue = fieldConfig?.[DYNAMIC_FIELDS_TYPE?.TEXT]?.default;

  useEffect(() => {
    if (editData) {
      if (!isEmpty(editData?.[fieldId]?.value)) {
        form?.setFieldsValue({
          systemFields: {
            [fieldId]: {
              [DYNAMIC_FIELDS_TYPE?.TEXT]: {
                value: editData?.[fieldId]?.value?.[0]
              }
            }
          }
        });
      } else {
        form?.setFieldsValue({
          systemFields: {
            [fieldId]: {
              [DYNAMIC_FIELDS_TYPE?.TEXT]: {
                value: defaultValue
              }
            }
          }
        });
      }
    } else {
      form?.setFieldsValue({
        systemFields: {
          [fieldId]: {
            NUMBER: {
              value: defaultValue
            }
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const renderText = () => {
    if (textType === 'MULTI_LINE') {
      return (
        <InputComponent.TextArea
          showCount
          rows={3}
          className="common-textarea mb-16"
          maxLength={maxLength}
          minLength={minLength}
          placeholder={`Enter your ${label}`}
        />
      );
    }
    if (textType === 'RICH_TEXT') {
      return <EditorComponent placeholder={`Enter your ${label}`} />;
    }
    return (
      <InputComponent
        name="Value"
        showCount
        maxLength={maxLength}
        minLength={minLength}
        placeholder={`Enter your ${label}`}
      />
    );
  };

  return (
    <div className="mt-37">
      <Form.Item
        name={['systemFields', fieldId, DYNAMIC_FIELDS_TYPE?.TEXT, 'value']}
        required={required}
        label={label}
        tooltip={
          toolTipCheck && {
            title: toolTipText,
            icon: <InfoCircleOutlined />
          }
        }
        rules={
          textType !== 'RICH_TEXT'
            ? [
                {
                  required,
                  message: 'This field is required. Please fill it in.'
                },
                {
                  max: maxLength,
                  message: `Please Enter Max ${maxLength} Characters`
                },
                {
                  min: minLength,
                  message: `Please Enter Min ${minLength} Characters`
                }
              ]
            : [
                {
                  validator(_, value) {
                    const stringLength = strippedString(value)?.length;
                    if (required && (!value || !stringLength)) {
                      return Promise?.reject(
                        new Error('This field is required. Please fill it in.')
                      );
                    }

                    if (stringLength > maxLength) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject(
                        `Please Enter Max ${maxLength} Characters`
                      );
                    }
                    if (stringLength < minLength) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject(
                        `Please Enter Min ${minLength} Characters`
                      );
                    }
                    return Promise?.resolve();
                  }
                }
              ]
        }
        className={`${toolTipCheck && 'label-with-tooltip'}`}
      >
        {renderText()}
      </Form.Item>
    </div>
  );
};

export default RenderSystemText;
