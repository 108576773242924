import { Card, Result } from 'antd';
import React from 'react';

const Error404 = () => (
  <Card className="full-height-card card-body-padding">
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  </Card>
);

export default Error404;
