import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import './changeQuote.less';
import ChangeQuotePage from './pages/ChangeQuotePage';

const ChangeQuoteWrapper = ({
  match: { params: { slug = '' } = {} } = {},
  quotesData = null
}) => {
  return (
    <div className="change-quote-module">
      <Switch>
        <Route
          exact
          path={`/${slug}${ROUTES?.CHANGE_QUOTE}`}
          render={() => <ChangeQuotePage quotesData={quotesData} />}
        />
      </Switch>
    </div>
  );
};

export default withRouter(ChangeQuoteWrapper);
