import { gql } from '@apollo/client';

export const CREATE_LEAD_TRACKING = gql`
  mutation createLeadTracking($data: LeadTrackingInput!) {
    createLeadTracking(data: $data) {
      status
    }
  }
`;

export const CHANGE_QUOTE = gql`
  mutation changeQuote($data: ChangeQuoteInput!) {
    changeQuote(data: $data) {
      message
      status
      data {
        oldPackage
        newPackage
      }
    }
  }
`;

export const CREATE_LEAD_STORE_DATA = gql`
  mutation createLeadStoreData(
    $data: LeadStoreInput!
    $where: CreateLeadStoreWhereInput
  ) {
    createLeadStoreData(data: $data, where: $where) {
      id
      data
      visitorId
    }
  }
`;

export const CREATE_LEAD_V2 = gql`
  mutation createLeadV2($data: LeadV2Input!) {
    createLeadV2(data: $data) {
      message
      status
    }
  }
`;

export const GET_SIGN_URLS = gql`
  mutation generateSignedUrls($action: String!, $data: [signedUrlData]!) {
    generateSignedUrls(action: $action, data: $data) {
      signedRequest
      url
    }
  }
`;

export const GET_PUBLIC_SIGN_URL = gql`
  mutation generatePublicSignedUrl($action: String!, $data: [signedUrlData]!) {
    generatePublicSignedUrl(action: $action, data: $data) {
      signedRequest
      key
      url
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteLeadStoreData($where: CreateLeadStoreWhereInput!) {
    deleteLeadStoreData(where: $where) {
      message
    }
  }
`;
