import { Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { isMobileDevice } from '../../common/utils';
import ManualEntryForm from './ManualEntryForm';
import MapEntryForm from './MapEntryForm';

const OtherConfigsForm = ({
  widgetConfig = null,
  tenantLogo = null,
  primaryColor = '#1890ff',
  form = null,
  setShowModal
}) => {
  const { dispatch } = useContext(AppContext);

  const [selectedTab, setSelectedTab] = useState(
    !isMobileDevice() ? 'MAP_ENTRY' : 'MANUAL_ENTRY'
  );
  const [area, setArea] = useState(0);

  useEffect(() => {
    if (!isMobileDevice()) {
      if (widgetConfig?.manualEntry?.default) {
        setSelectedTab('MANUAL_ENTRY');
      }
      if (widgetConfig?.mapEntry?.default) {
        setSelectedTab('MAP_ENTRY');
      }
    } else {
      setSelectedTab('MANUAL_ENTRY');
    }
  }, [widgetConfig]);

  const items = [
    !isMobileDevice() && {
      key: 'MAP_ENTRY',
      label: widgetConfig?.mapEntry?.title,
      children: (
        <MapEntryForm
          widgetConfig={widgetConfig}
          form={form}
          tenantLogo={tenantLogo}
          primaryColor={primaryColor}
          area={area}
          setArea={setArea}
          setSelectedTab={setSelectedTab}
        />
      )
    },
    {
      key: 'MANUAL_ENTRY',
      label: widgetConfig?.manualEntry?.title,
      children: (
        <ManualEntryForm
          manualEntry={widgetConfig?.manualEntry}
          form={form}
          setShowModal={setShowModal}
          area={area}
        />
      )
    }
  ];

  const handleTabChange = (activeTab) => {
    setSelectedTab(activeTab);
    dispatch({
      type: 'SET_SHOW_SUBMIT_BTN',
      data: activeTab !== 'MAP_ENTRY'
    });
  };

  return (
    <Tabs
      items={items}
      activeKey={selectedTab}
      centered
      onChange={handleTabChange}
    />
  );
};

export default OtherConfigsForm;
