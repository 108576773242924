import { Button } from 'antd';
import { filter, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

const ServiceTypeWidget = ({ widgetConfig = null, projectId = null }) => {
  const [projectLobData, setProjectLobData] = useState([]);

  useEffect(() => {
    const filteredProjectArray = filter(
      widgetConfig?.lineOfBusinessConfigs,
      (item) => item?.lobId === projectId?.[0]
    );
    setProjectLobData(filteredProjectArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetConfig]);
  return (
    <div className="service-type-component">
      {map(projectLobData?.[0], (item, key) => {
        if (item?.show) {
          return (
            <div key={key}>
              <div>
                <span className="bold-label">{item?.label}</span>
              </div>
              <div>
                <span className="thin-label">{item?.description}</span>
              </div>
              <div className="button-section">
                <Button
                  className="common-button"
                  type="primary"
                  htmlType="submit"
                >
                  {item?.ctaButtonLabel}
                </Button>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default withRouter(ServiceTypeWidget);
