import { ZoomOutOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { map } from 'lodash';
import React, { useState } from 'react';
import placeHolderImage from '../assets/images/place-holder-image-master.svg';
import {
  HORIZONTAL_PADDING,
  PORTAL_WIDTH,
  PRIMARY_COLOR
} from '../common/constants';
import ModalComponent from './ModalComponent';
import PickListCheckedIconComponent from './PickListCheckedIconComponent';
import './styles/TileGridComponent.less';

const ImageBlock = ({
  selectedKey = null,
  item = null,
  imageUrl = null,
  primaryColor = null
}) => {
  if (selectedKey !== item?.key && !item?.default) {
    return (
      <img
        alt="logo"
        className="card-image"
        src={imageUrl || placeHolderImage}
        onError={(e) => {
          e.target.src = placeHolderImage;
        }}
        height={75}
      />
    );
  }
  return <PickListCheckedIconComponent color={primaryColor} />;
};

const WithoutImageBlock = ({
  selectedKey = null,
  item = null,
  primaryColor = null
}) => {
  if (selectedKey !== item?.key && !item?.default) {
    return (
      <span className="card-title" title={item?.label}>
        {item?.label}
      </span>
    );
  }
  return (
    <>
      <PickListCheckedIconComponent color={primaryColor} />
      <span className="card-title" title={item?.label}>
        {item?.label}
      </span>
    </>
  );
};

const TileGridComponent = (props) => {
  const {
    primaryColor = PRIMARY_COLOR,
    gridClassName,
    displayStyle = 'TILES',
    handleClick,
    renderItems = [],
    renderImages = false,
    imagePreview = false,
    renderDescriptions = false,
    selectedKey = null,
    haveLabel = false,
    showCheckedIconUi = true
  } = props;

  const [showPreview, setShowPreview] = useState(false);
  const [record, setRecord] = useState(false);

  const closeModel = () => {
    setRecord(null);
    setShowPreview(false);
  };

  const showPreviewModal = (item) => {
    setRecord(item);
    setShowPreview(true);
  };

  return (
    <div className={`tile-grid ${!haveLabel ? 'mt-35' : ''}`}>
      {renderItems?.length > 0 && (
        <Card
          className={`${renderItems?.length === 1 ? 'center-aligned' : ''} `}
        >
          {map(renderItems, (item) => {
            return (
              <Card.Grid
                onClick={() => {
                  if (handleClick) {
                    handleClick(item);
                  }
                }}
                key={item?.key}
                className={`grid-card ${
                  displayStyle === 'TILES' ? 'tile-view' : ''
                }${displayStyle === 'LIST' ? 'list-view' : ''} ${
                  selectedKey === item?.key ? 'card-selected' : ''
                }${gridClassName || ''}`}
                hoverable
              >
                {showCheckedIconUi ? (
                  <>
                    <div className="d-flex flex-vertical align-center">
                      {renderImages && (
                        <>
                          <ImageBlock
                            item={item}
                            imageUrl={item?.logo?.url || item?.image?.url}
                            selectedKey={selectedKey}
                            primaryColor={primaryColor}
                          />
                          <span className="card-title" title={item?.label}>
                            {item?.label}
                          </span>
                        </>
                      )}
                      {!renderImages && (
                        <WithoutImageBlock
                          item={item}
                          selectedKey={selectedKey}
                          primaryColor={primaryColor}
                        />
                      )}
                      {renderDescriptions && (
                        <span
                          className="card-description line-clamp"
                          title={item?.blockDescription}
                        >
                          {item?.blockDescription}
                        </span>
                      )}
                      {imagePreview && (
                        <span
                          className="zoom-image"
                          title="zoom-image"
                          onClick={(e) => {
                            e?.stopPropagation();
                            showPreviewModal(item);
                          }}
                        >
                          <ZoomOutOutlined /> zoom
                        </span>
                      )}
                    </div>
                  </>
                ) : (
                  <span className="card-title" title={item?.label}>
                    {item?.label}
                  </span>
                )}
              </Card.Grid>
            );
          })}
        </Card>
      )}
      {showPreview && (
        <ModalComponent
          open={showPreview}
          footer={null}
          onCancel={closeModel}
          destroyOnClose
          width={PORTAL_WIDTH - 2 * HORIZONTAL_PADDING}
          wrapClassName="preview-modal"
        >
          <>
            <div className="modal-title">
              <span>{record?.label}</span>
            </div>
            <div className="d-flex justify-center">
              <img
                alt="logo"
                className="modal-image"
                src={
                  record?.logo?.url || record?.image?.url || placeHolderImage
                }
                onError={(e) => {
                  e.target.src = placeHolderImage;
                }}
              />
            </div>
            <Button
              className="fill-width"
              type="primary"
              onClick={() => {
                handleClick(record);
                setShowPreview(false);
              }}
            >
              Select
            </Button>
          </>
        </ModalComponent>
      )}
    </div>
  );
};

export default TileGridComponent;
