import { Col, Form, Row } from 'antd';
import { map } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { DEFAULT_STAIRS_DEPTH_OPTIONS } from '../../common/constants';
import { calculateVolume } from '../../common/utils';
import DecimalNumberComponent from '../DecimalNumberComponent';
import NumberComponent from '../NumberComponent';
import SelectComponent from '../SelectComponent';

const { Option } = SelectComponent;

const ManualEntryForm = ({
  manualEntry = null,
  form = null,
  setShowModal,
  area = 0
}) => {
  const { dispatch } = useContext(AppContext);

  const depthValue = Form?.useWatch(
    ['dimension', 'other', 'manualEntryDepth'],
    form
  );
  const areaValue = Form?.useWatch(
    ['dimension', 'other', 'manualEntryArea'],
    form
  );

  useEffect(() => {
    if (area > 0) {
      form?.setFieldValue(['dimension', 'other', 'manualEntryArea'], area);
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: true
      });
    } else {
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area]);

  useEffect(() => {
    if (depthValue && areaValue > 0) {
      const finalVolume = calculateVolume(depthValue, areaValue);
      form?.setFieldValue(
        ['dimension', 'other', 'manualEntryVolume'],
        finalVolume
      );
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: true
      });
    } else {
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false
      });
      form?.setFieldValue(
        ['dimension', 'other', 'manualEntryVolume'],
        undefined
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depthValue, areaValue]);

  const handleAreaChange = (changedValue) => {
    if (!changedValue) {
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false
      });
    }
  };
  return (
    <>
      <Row>
        <Col span={23}>
          <Form.Item
            name={['dimension', 'other', 'manualEntryArea']}
            className={manualEntry?.areaToolTipCheck ? 'hasDescription' : ''}
            rules={[
              {
                required: true,
                message: 'This field is required. Please fill it in.'
              }
            ]}
          >
            <DecimalNumberComponent
              className="dimension-decimal-input"
              placeholder={manualEntry?.areaText}
              decimalValue={2}
              onChange={handleAreaChange}
            />
          </Form.Item>
        </Col>
        <Col span={1}>
          <div className="measurement-span">sq/ft</div>
        </Col>
      </Row>
      {manualEntry?.areaToolTipCheck && (
        <div className="input-description">
          <span>{manualEntry?.areaToolTipText}</span>
        </div>
      )}
      <Row>
        <Col span={23}>
          <Form.Item
            name={['dimension', 'other', 'manualEntryDepth']}
            className={manualEntry?.depthToolTipCheck ? 'hasDescription' : ''}
            rules={[
              {
                required: true,
                message: 'This field is required. Please fill it in.'
              }
            ]}
          >
            <SelectComponent
              placeholder={manualEntry?.depthText}
              className="stairs-select"
              allowClear
            >
              {map(DEFAULT_STAIRS_DEPTH_OPTIONS, (item) => {
                return (
                  <Option key={item?.value} value={item?.value}>
                    {item?.label}
                  </Option>
                );
              })}
            </SelectComponent>
          </Form.Item>
        </Col>
      </Row>
      {manualEntry?.depthToolTipCheck && (
        <div className="input-description">
          <span>{manualEntry?.depthToolTipText}</span>
        </div>
      )}
      <Row>
        <Col span={23}>
          <Form.Item
            name={['dimension', 'other', 'manualEntryVolume']}
            className={manualEntry?.volumeToolTipCheck ? 'hasDescription' : ''}
            rules={[
              {
                required: true,
                message: 'This field is required. Please fill it in.'
              }
            ]}
          >
            <NumberComponent
              placeholder={manualEntry?.volumeText}
              readOnly
              className="dimension-read-only"
            />
          </Form.Item>
        </Col>
        <Col span={1}>
          <div className="measurement-span">cu/ft</div>
        </Col>
      </Row>
      {manualEntry?.volumeToolTipCheck && (
        <div className="input-description">
          <span>{manualEntry?.volumeToolTipText}</span>
        </div>
      )}

      {manualEntry?.descriptionCheck && (
        <div className="mt-16">
          <span>{manualEntry?.descriptionText}</span>
        </div>
      )}
      <div className="measurement-tips-link" onClick={() => setShowModal(true)}>
        Measurement Tips
      </div>
    </>
  );
};

export default ManualEntryForm;
