import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Tooltip } from 'antd';
import { isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import CommonPickListWidget from '../CommonPickListWidget';

import { PRIMARY_COLOR } from '../../common/constants';
import SelectComponent from '../SelectComponent';

const { Option } = SelectComponent;

const RenderCustomPickList = ({
  widgetConfig = null,
  setSavedData,
  editData = null,
  savedData = null,
  pageId = null,
  form = null,
  primaryColor = PRIMARY_COLOR
}) => {
  const { dispatch } = useContext(AppContext);
  const label = widgetConfig?.config?.label;
  const tooltipChecked = widgetConfig?.config?.tooltip;
  const multiSelection = widgetConfig?.config?.multiSelection;
  const tooltipValue = widgetConfig?.config?.tooltipValue;
  const hideLabel = widgetConfig?.config?.rules?.hideLabel;
  const required = widgetConfig?.config?.rules?.required;
  const displayStyle = widgetConfig?.config?.displayStyle;
  const renderImages = widgetConfig?.config?.images;
  const imagePreview = widgetConfig?.config?.imagePreview;
  const hideContinueButton = widgetConfig?.config?.hideContinueButton;
  const optionsDescription = widgetConfig?.config?.optionsDescription;
  const listItems = widgetConfig?.config?.listItems;
  const rules = widgetConfig?.config?.rules;
  const [selectedKey, setSelectedKey] = useState(null);
  const [multipleKeys, setMultipleKeys] = useState([]);

  useEffect(() => {
    if (!isEmpty(editData)) {
      if (!isEmpty(editData?.[pageId])) {
        setSavedData({
          customFields: {
            ...savedData?.customFields,
            [pageId]: null
          }
        });
        form?.setFieldsValue({
          customFields: {
            [pageId]: editData?.[pageId]
          }
        });
        if (!multiSelection) {
          setSelectedKey(editData?.[pageId]?.[0]);
        } else {
          setMultipleKeys([...multipleKeys, ...editData?.[pageId]]);
        }
      }
    } else if (widgetConfig) {
      map(widgetConfig?.config?.listItems, (item) => {
        if (item?.default) {
          setSavedData({
            customFields: {
              ...savedData?.customFields,
              [pageId]: [item?.uuid]
            }
          });
          form?.setFieldsValue({
            customFields: {
              [pageId]: [item?.uuid]
            }
          });
          if (!multiSelection) {
            setSelectedKey(item?.uuid);
          } else {
            setMultipleKeys([...multipleKeys, item?.uuid]);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, widgetConfig]);

  // if picklist show/hide button logic
  useEffect(() => {
    if (hideContinueButton) {
      // if user want to hide cta button
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false
      });
    } else if (required && multiSelection) {
      // check if pick list is required and if picklist is multiselect and user doesn't select any option from picklist then hide cta button
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: multipleKeys?.length !== 0
      });
    } else if (required && !multiSelection) {
      // check if pick list is required and if picklist is not multiselect and user doesn't select any option from picklist then hide cta button
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: selectedKey
      });
    } else {
      // default button will be shown to user
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleKeys, selectedKey]);

  const handleRedirect = (item) => {
    if (!multiSelection) {
      if (selectedKey !== item?.uuid) {
        setSelectedKey(item?.uuid);
        setSavedData({
          customFields: {
            ...savedData?.customFields,
            [pageId]: [item?.uuid]
          }
        });
        if (hideContinueButton) {
          form?.submit();
        }
      } else {
        setSelectedKey(null);
        setSavedData({
          customFields: {
            ...savedData?.customFields,
            [pageId]: []
          }
        });
      }
    } else if (!multipleKeys?.includes(item?.uuid)) {
      setMultipleKeys([...multipleKeys, item?.uuid]);
      setSavedData({
        customFields: {
          ...savedData?.customFields,
          [pageId]: [...multipleKeys, item?.uuid]
        }
      });
    } else {
      const newSelectedOptions = multipleKeys?.filter((selectedItem) => {
        return selectedItem !== item?.uuid;
      });
      setMultipleKeys([...newSelectedOptions]);
      setSavedData({
        customFields: {
          ...savedData?.customFields,
          [pageId]: [...newSelectedOptions]
        }
      });
    }
  };

  const handlePickListChange = (keys) => {
    if (keys?.length > 0) {
      if (!multiSelection) {
        setSelectedKey(keys);
      } else {
        setMultipleKeys([...multipleKeys, keys]);
      }
    } else if (!multiSelection) {
      setSelectedKey(null);
    } else {
      setMultipleKeys([]);
    }
  };

  return (
    <>
      {displayStyle === 'DROPDOWN' ? (
        <Form.Item
          name={['customFields', pageId]}
          rules={[
            {
              required: !!required,
              message: 'This field is required. Please fill it in.'
            }
          ]}
          label={hideLabel ? ' ' : label}
          tooltip={
            tooltipChecked && {
              title: tooltipValue,
              icon: <InfoCircleOutlined />
            }
          }
          className={`${!required && 'hide-required-mark'} ${
            tooltipChecked && 'label-with-tooltip'
          }`}
        >
          <SelectComponent
            className="fill-width pick-list-drop-down"
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
            }
            optionLabelProp="label"
            mode={multiSelection ? 'multiple' : ''}
            onChange={handlePickListChange}
          >
            {map(listItems, (item) => {
              return (
                <Option key={item?.uuid} value={item?.uuid} label={item?.label}>
                  <div key={item?.uuid} className="dropdown-option">
                    <span className="option-title">{item?.label}</span>
                    {optionsDescription && (
                      <span className="option-description">
                        {item?.blockDescription || item?.description}
                      </span>
                    )}
                  </div>
                </Option>
              );
            })}
          </SelectComponent>
        </Form.Item>
      ) : (
        <div className={`${rules?.readOnly && 'cursor-disabled'}`}>
          <div className="custom-form-item">
            {!hideLabel && <div className="item-label">{label}</div>}
            {required && <span className="required-mark">*</span>}
            {tooltipChecked && (
              <Tooltip title={tooltipValue}>
                <InfoCircleOutlined className="custom-tooltip" />
              </Tooltip>
            )}
          </div>
          <CommonPickListWidget
            displayStyle={displayStyle}
            renderImages={renderImages}
            imagePreview={imagePreview}
            renderDescriptions={optionsDescription}
            haveLabel
            renderItems={map(listItems, (item) => {
              return {
                key: item?.uuid,
                ...item
              };
            })}
            isMultiple={multiSelection}
            selectedKey={selectedKey}
            multipleKeys={multipleKeys}
            rules={rules}
            keyName="uuid"
            form={form}
            handleRedirect={handleRedirect}
            primaryColor={primaryColor}
          />
        </div>
      )}
    </>
  );
};

export default RenderCustomPickList;
